import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import InputSearch from '../../component/common/InputSearch';
import detailIcon from '../../assets/images/images/detail.svg';
import deleteIcon from '../../assets/images/images/delete.png';
import Paginate from '../../component/Paginate/index';
import Table from '../../component/common/TableStudent';
import {
  LIST_COMPETITION,
  DELETE_COMPETITION,
  GET_LIST_BRANCH,
  GET_CLUB_BRANCH
} from '../../constants/config';
import {
  postDataWithParams,
  deleteById,
  getTakenData,
  getDataByID
} from '../../services/base_services';
import { confirmDelete, showMessage } from '../../helpers/table';
import { formatDate } from '../../helpers/form';
import NewButton from '../../component/common/NewButton';
import { PER_PAGE } from '../../constants/variable';
import Can from '../../services/Can';
import SelectCustom from '../../component/common/SelectCustom';

class Competition extends Component {
  constructor(props) {
    super(props);
    this.keyword = '';
    this.branch = '';
    this.club = '';
    this.state = {
      isLoading: true,
      loadData: false,
      perPage: PER_PAGE,
      paginate: [],
      tableData: [],
      listBranch: [],
      initallbranch: 0,
      listClub: [],
      initallClub: 0,
      tableHeaders: [
        {
          label: 'STT',
          index: 'stt',
          option: {
            className: 'text-center position-relative'
          },
          callback: null
        },
        {
          label: 'TÊN KỲ THI',
          option: {
            className: 'text-left'
          },
          index: null,
          callback: (data) => {
            return (
              <div>
                <span>{data && data.title}</span>
              </div>
            );
          }
        },
        {
          label: 'ĐỊA ĐIỂM',
          index: null,
          option: {
            className: 'text-center'
          },
          callback: (data) => {
            return (
              <div>
                <span>{data && data.address}</span>
              </div>
            );
          }
        },
        {
          label: 'Thời gian',
          index: null,
          option: {
            className: 'text-center'
          },
          callback: (data) => {
            return (
              <div>
                <span class="set-width-120">
                  {formatDate(data && data.exam_date, 'dd-mm-yyyy')}
                </span>
              </div>
            );
          }
        },
        {
          label: 'Số lượng người thi',
          index: null,
          option: {
            className: 'text-center'
          },
          callback: (data) => {
            return (
              <div>
                <span>{data && data.num_member}</span>
              </div>
            );
          }
        },
        {
          label: 'CHỨC NĂNG',
          index: null,
          option: {
            className: 'text-center'
          },
          callback: (data) => {
            if (data.id) {
              return (
                <div className="btn-group">
                  <span
                    data-tip="Xem chi tiết Kỳ Thi"
                    className="link-action ml-2 mr-2"
                  >
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        this.onEdit(data.id);
                      }}
                      onKeyPress={() => {}}
                    >
                      <img alt="edit" src={detailIcon} className="btn-icon" />
                    </div>
                  </span>
                  <Can I="competition_del" a="competition">
                    <span data-tip="Xóa Kỳ Thi" className="link-action ml-2">
                      <div
                        role="button"
                        tabIndex={0}
                        onClick={() => this.onDelete(data.id)}
                        onKeyPress={() => {}}
                      >
                        <img
                          alt="delete"
                          src={deleteIcon}
                          className="btn-icon"
                        />
                      </div>
                    </span>
                  </Can>
                  <ReactTooltip
                    className="tooltip-button"
                    place="bottom"
                    effect="float"
                  />
                </div>
              );
            }
            return '';
          }
        }
      ]
    };
  }

  componentDidMount = () => {
    this.getList();
    this.getListBranch();
    this.getListClub();
  };

  onEdit = (id) => {
    const { history } = this.props;
    history.push(`/active/competition/manage/${id}`);
  };

  onDelete = (id) => {
    confirmDelete().then((res) => {
      if (res.value === true) {
        this.removeRequest(id);
      }
    });
  };

  removeRequest = (id) => {
    const { tableData, page } = this.state;
    deleteById(DELETE_COMPETITION, id)
      .then((res) => {
        showMessage('Xóa thành công', true);
        if (tableData.length === 1 && page > 1)
          this.setState({ page: page - 1 });
        this.getList();
      })
      .catch(() => {
        showMessage('CÓ LỖI XẢY RA!', false);
      });
  };

  getListBranch = async () => {
    await getTakenData(GET_LIST_BRANCH)
      .then((res) => {
        if (res?.data?.length == 1) {
          this.setState({
            listBranch: [...res?.data]
          });
          this.getListRequest({
            page: 1,
            per_page: this?.state?.perPage,
            keyword: this?.keyword?.search_text || '',
            branch: Number(res?.data?.[0]?.id) || 0
          });
          this.setState({
            page: 1,
            initallbranch: Number(res?.data?.[0]?.id) || 0
          });
          if (this.props?.user?.user?.position_id == 3) {
            getDataByID(GET_CLUB_BRANCH, res?.data?.[0]?.id).then((res) => {
              const clubByClubId = res?.data?.find(
                (club) => club.id == this?.props?.user?.user?.club_id
              );
              this.getListRequest({
                page: 1,
                per_page: this?.state?.perPage,
                keyword: this?.keyword?.search_text || '',
                branch: this?.state?.initallbranch || 0,
                club: clubByClubId?.id || 0
              });
              this.setState({
                listClub: [clubByClubId],
                initallClub: this?.props?.user?.user?.club_id
              });
            });
          } else {
            this.getListClub(res?.data?.[0]?.id);
          }
        } else {
          this.setState({
            listBranch: [
              {
                id: 0,
                name: 'Tất cả phân đường'
              },
              ...res?.data
            ]?.sort((a, b) => a?.id - b?.id)
          });
        }
      })
      .catch((err) => err);
  };

  getListClub = async (idBranch) => {
    await getDataByID(GET_CLUB_BRANCH, idBranch)
      .then((res) => {
        this.setState({
          listClub: [
            {
              id: 0,
              name: 'Tất cả CLB / Võ đường'
            },
            ...res.data
          ]?.sort((a, b) => a?.id - b?.id)
        });
      })
      .catch((err) => err);
  };

  getList = async () => {
    const { page, perPage } = this.state;
    this.getListRequest({
      page: 1,
      per_page: perPage,
      keyword: this.keyword.search_text || ''
    });
    this.setState({
      page: 1
    });
  };

  getListRequest = async (params) => {
    const { perPage } = this.state;

    await postDataWithParams(LIST_COMPETITION, params)
      .then((res) => {
        this.setState({
          tableData: res.data.data,
          paginate: {
            ...res.data.paginate,
            perPage
          },
          isLoading: false
        });
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  onChangeSearch = (e) => {
    clearTimeout(this.timer);
    let { perPage, initallClub, initallbranch } = this.state;
    this.keyword = e?.target?.value;
    this.timer = setTimeout(() => {
      this.getListRequest({
        page: 1,
        per_page: perPage,
        keyword: this.keyword || '',
        branch: initallbranch || 0,
        club: initallClub || 0
      });
    }, 500);
    this.setState({
      page: 1
    });
  };

  handleChangeBranch = (e) => {
    let { perPage } = this.state;
    this.club = '';

    this.getListRequest({
      page: 1,
      per_page: perPage,
      keyword: this.keyword,
      branch: Number(e?.target?.value) || 0,
      club: 0
    });
    this.setState({
      page: 1,
      initallbranch: Number(e?.target?.value) || 0,
      initallClub: 0
    });

    this.getListClub(e?.target?.value);
  };

  handleChangeClub = (e) => {
    let { perPage } = this.state;

    this.getListRequest({
      page: 1,
      per_page: perPage,
      keyword: this.keyword,
      branch: this?.state?.initallbranch,
      club: Number(e?.target?.value) || 0
    });

    this.setState({
      page: 1,
      initallClub: Number(e?.target?.value) || 0
    });
  };

  changePage = (pageNumber) => {
    const { page, perPage, initallClub, initallBranch } = this.state;
    if (pageNumber !== page) {
      this.getListRequest({
        page: pageNumber,
        per_page: perPage,
        keyword: this.keyword.search_text || '',
        branch: initallBranch || 0,
        club: initallClub || 0
      });
      this.setState({ page: pageNumber });
    }
  };

  render() {
    const {
      tableHeaders,
      paginate,
      tableData,
      isLoading,
      listBranch,
      listClub,
      initallBranch,
      initallClub
    } = this.state;

    return (
      <>
        <div className="body-right-bottom course-wrap">
          <div className="container-fluid content">
            <div className="row top-table">
              <div className="col-md-12 top-table-title">
                <p>Quản Lý Hoạt Động</p>
              </div>
              <div className="col-md-1 top-table-border  "></div>
              <div className="col-md-12 top-table-text">Tất cả kỳ thi</div>
            </div>
            <div>
              <span className="statistic-title">Danh Sách Kỳ Thi</span>
            </div>
            <div className="row header-wrap">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <InputSearch onChange={this.onChangeSearch} />
              </div>

              <div className="col-sm-12 col-md-4 col-lg-4 content__flex-end">
                <Can I="competition_add" a="competition">
                  <Link to="/active/competition/add">
                    <NewButton />
                  </Link>
                </Can>
              </div>
            </div>

            <div className="row">
              {this?.props?.user?.user?.position_id < 2 && (
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <SelectCustom
                    className="form-control-select"
                    errors={[]}
                    name="type"
                    data={listBranch}
                    key_value="id"
                    key_label="name"
                    onChange={this.handleChangeBranch}
                    defaultValue={initallBranch}
                  />
                </div>
              )}
              {this?.props?.user?.user?.position_id < 3 && (
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <SelectCustom
                    className="form-control-select"
                    label=""
                    errors={[]}
                    key_value="id"
                    key_label="name"
                    data={listClub}
                    onChange={this.handleChangeClub}
                    defaultValue={initallClub}
                  />
                </div>
              )}
            </div>

            <div className="row">
              <div className="col-md-12">
                <Table
                  fixed
                  tableHeaders={tableHeaders}
                  tableData={tableData}
                  tablePaginate={paginate}
                  isLoading={isLoading}
                />
              </div>
              <div className="table-pagenatie ml-auto">
                <div className="paginate-wrapper">
                  <Paginate paginate={paginate} changePage={this.changePage} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Competition.propTypes = {
  history: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps, null)(withRouter(Competition));
