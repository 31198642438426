/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react';
import propTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import CountUp from 'react-countup';
import { GET_LIST_FORM, DELETE_FORM } from '../../constants/config';
import { PER_PAGE } from '../../constants/variable';
import {
  confirmDelete,
  checkAccept,
  showMessage,
  moneyFormat
} from '../../helpers/table';
import createNotification from '../../component/common/Notification';
import { postData, deleteById } from '../../services/base_services';

import Table from '../../component/common/Table';
import Paginate from '../../component/Paginate';
import NewButton from '../../component/common/NewButton';
import InputSearch from '../../component/common/InputSearch';
import editIcon from '../../assets/images/icon/edit-kn.svg';
import deleteIcon from '../../assets/images/icon/delete-kn.svg';

import { formatDate } from '../../helpers/form';
const type = [
  {
    name: 'Về nghĩa dũng',
    value: 0
  },
  {
    name: 'Quy trình nghĩa dũng',
    value: 1
  },
  {
    name: 'Nguồn gốc và Lịch sữ',
    value: 2
  },
  {
    name: 'Hệ thống phân đường',
    value: 3
  },
  {
    name: 'Kỹ thuật',
    value: 4
  },
  {
    name: 'Sinh hoạt',
    value: 5
  },
  {
    name: 'Thư viện',
    value: 6
  }
];
const AdsTable = (props) => {
  const { history } = props;
  const perPage = PER_PAGE || 5;
  const [tableData, setTableData] = useState([]);
  const [paginate, setPaginate] = useState([]);
  const [numberDelete, setNumberDelete] = useState(0);
  const typingTimeoutRef = useRef(null);
  const [key, setKey] = useState('');
  const [idEvent, setIdEvent] = useState('');
  const dateRef = useRef('');
  const [req, setReq] = useState({
    keyword: key,
    page: 1,
    // eslint-disable-next-line camelcase
    per_page: perPage,
    event_id: idEvent,
    date: ''
  });

  const formatDate2 = 'dd-MM-yyyy';
  const IDEventRef = useRef('');
  const [listEventForm, setListEventForm] = useState([]);
  const [errors, setErrors] = useState([]);
  const type = [
    {
      name: 'Về nghĩa dũng',
      value: 0
    },
    {
      name: 'Quy trình nghĩa dũng',
      value: 1
    },
    {
      name: 'Nguồn gốc và Lịch sữ',
      value: 2
    },
    {
      name: 'Hệ thống phân đường',
      value: 3
    },
    {
      name: 'Kỹ thuật',
      value: 4
    },
    {
      name: 'Sinh hoạt',
      value: 5
    },
    {
      name: 'Thư viện',
      value: 6
    },
    {
      name: 'Điều khoản sử dụng',
      value: 7
    }
  ];
  const tableHeaders = [
    {
      label: 'STT',
      index: 'stt',
      option: {
        className: 'text-center position-relative'
      },
      callback: null
    },
    {
      label: 'Tiêu đề',
      index: null,
      callback: (data) => {
        return (
          <div>
            <span>{data && data.title}</span>
          </div>
        );
      }
    },
    {
      label: 'Loại Biểu Mẫu',
      index: null,
      option: {
        className: 'text-center'
      },
      callback: (data) => {
        return (
          <div>
            <span>
              {data && data.type == 1
                ? 'Quy trình nghĩa dũng'
                : type[data.type].name}
            </span>
          </div>
        );
      }
    },
    {
      label: 'CHỨC NĂNG',
      index: null,
      option: {
        className: 'text-center fixed-collumn'
      },
      callback: (data) => {
        if (data && data.id) {
          return (
            <div className="btn-group">
              <span
                data-tip="Chỉnh Sửa Quy trình, biểu mẫu"
                className="link-action ml-2 mr-2"
              >
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => onEditForm(data.id)}
                  onKeyPress={() => {}}
                >
                  <img alt="edit" src={editIcon} className="btn-icon" />
                </div>
              </span>
              <span
                data-tip="Xóa Quy trình, biểu mẫu"
                className="link-action ml-2"
              >
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => onDeleteForm(data.id)}
                  onKeyPress={() => {}}
                >
                  <img alt="delete" src={deleteIcon} className="btn-icon" />
                </div>
              </span>
              <ReactTooltip
                className="tooltip-button"
                place="bottom"
                effect="float"
              />
            </div>
          );
        }
        return '';
      }
    }
  ];

  const getListForm = async (newReq) => {
    //getListEventForm
    await postData(GET_LIST_FORM, newReq)
      .then((res) => res && res.data)
      .then((res) => {
        setPaginate({ ...res.paginate, perPage });
        setTableData(res && res.data);
      });
  };
  const deleteForm = (id) => {
    deleteById(DELETE_FORM, id).then(() => {
      setNumberDelete(numberDelete + 1);
      createNotification('success', `Bạn đã xoá thành công`);
    });
  };
  const onEditForm = (id) => {
    history.push(`/form/edit/${id}`);
  };
  const onDeleteForm = (id, name) => {
    confirmDelete().then((res) => {
      if (res && res.value) {
        deleteForm(id, name);
      }
    });
  };
  const changePage = (pageNumber) => {
    setReq({
      ...req,
      page: pageNumber
    });
  };
  const onChangeSearch = (e) => {
    const value = e.target.value ? e.target.value : '';
    setKey(value);
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      setReq({
        ...req,
        keyword: value,
        page: 1
      });
    }, 500);
  };

  useEffect(() => {
    getListForm(req);
  }, [req, numberDelete]);

  return (
    <>
      <section className="body-right-bottom course-wrap">
        <div className="container-fluid content">
          <div className="row top-table">
            <div className="col-md-12 top-table-title">
              <p>Quản Lý Quy Trình , Biểu Mẫu </p>
            </div>
            <div className="col-md-1 top-table-border "></div>
          </div>
          <div className="content-form content-form--donate">
            <div className="row header-wrap">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <InputSearch onChange={onChangeSearch} />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 content__flex-end">
                <Link to="/form/new">
                  <NewButton />
                </Link>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Table
                tableData={tableData}
                tableHeaders={tableHeaders}
                tablePaginate={paginate}
              />
            </div>
            <div className="table-pagenatie ml-auto">
              <div className="paginate-wrapper">
                <Paginate
                  paginate={paginate}
                  changePage={changePage}
                  per={perPage}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default withRouter(AdsTable);

AdsTable.propTypes = {
  history: propTypes.isRequired
};
