import React, { Component } from 'react';
import InputSearch from '../../component/common/InputSearch';
import { Link } from 'react-router-dom';
import Can from '../../services/Can';
import NewButton from '../../component/common/NewButton';
import ReactTooltip from 'react-tooltip';
import Table from '../../component/common/TableStudent';
import Paginate from '../../component/Paginate/index';
import CheckboxCustom from '../../component/common/CheckboxCustom';
import checkIcon from '../../assets/images/icon/box-ok-kn.svg';
import { Modal } from 'react-bootstrap';

import { formatDate } from '../../helpers/form';
import detail from '../../assets/images/images/detail.svg';
import { showMessage } from '../../helpers/table';

import editIcon from '../../assets/images/icon/edit.svg';
import deleteIcon from '../../assets/images/images/delete.png';
import {
  destructServerErrors,
  destructServerMessage
} from '../../helpers/error';
import {
  getTakenData,
  putDataAccept,
  getDataByParams
} from '../../services/base_services';

export default class RequestList extends Component {
  constructor(props) {
    super(props);
    this.keyword = '';

    this.state = {
      tableHeaders: [
        {
          label: 'STT',
          index: 'stt',
          option: {
            className: 'text-center position-relative'
          },
          callback: (data) => {
            if (data?.index !== null)
              return (
                <div>
                  <span className="text-center">{data?.index}</span>
                </div>
              );
          }
        },

        {
          label: 'TÊN VÕ SINH',
          option: {
            className: 'width-200'
          },
          index: null,
          callback: (data) => {
            return (
              <div>
                <span className="text-left">{data?.user?.name}</span>
              </div>
            );
          }
        },
        {
          label: 'TÊN TƯ LIỆU',
          option: {
            className: 'width-300'
          },
          index: null,
          callback: (data) => {
            return (
              <div>
                <span className="text-left">{data?.library?.title}</span>
              </div>
            );
          }
        },
        {
          label: 'SỐ ĐIỆN THOẠI',
          index: null,
          option: {
            className: 'text-center width-200'
          },
          callback: (data) => {
            return (
              <div>
                <span className="text-center">{data?.user?.phone}</span>
              </div>
            );
          }
        },
        {
          label: 'NGÀY YÊU CẦU',
          index: null,
          option: {
            className: 'text-center width-200'
          },
          callback: (data) => {
            return (
              <div>
                <span className="text-center">
                  {formatDate(data?.request_date)}
                </span>
              </div>
            );
          }
        },

        {
          label: 'CHỨC NĂNG',
          index: null,
          option: {
            className: 'text-center fixed-collumn'
          },
          callback: (data) => {
            if (data.id) {
              return (
                <div className="btn-group">
                  <span
                    data-tip="Chấp nhận yêu cầu"
                    className="link-action ml-2 mr-2"
                  >
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        this.onAcceptRequest(data.id);
                      }}
                    >
                      <img alt="edit" src={checkIcon} className="btn-icon" />
                    </div>
                  </span>

                  <Can I="library_photo_del" a="library">
                    <Can></Can>
                    <span
                      data-tip="Từ chối yêu cầu"
                      className="link-action ml-2"
                    >
                      <div
                        role="button"
                        tabIndex={0}
                        onClick={() => this.onRejectRequest(data.id)}
                        onKeyPress={() => {}}
                      >
                        <img
                          alt="delete"
                          src={deleteIcon}
                          className="btn-icon"
                        />
                      </div>
                    </span>
                  </Can>
                  <ReactTooltip
                    className="tooltip-button"
                    place="bottom"
                    effect="float"
                  />
                </div>
              );
            }
            return '';
          }
        }
      ],
      tableData: [],
      paginate: [],
      isLoading: false,
      open: false,
      id: '',
      search_text: ''
    };
  }

  onAcceptRequest = async (data) => {
    await putDataAccept('library_member', 'accept-request', data)
      .then(this.getListDocument())
      .then((res) => {
        showMessage(res.data.message, true);
        this.setState({
          errors: [],
          status: true
        });
        // history.goBack();
      })
      .catch((err) => {
        const errs = destructServerMessage(err);
        const errss = destructServerErrors(err);

        this.setState({
          errors: errss
        });
        if (err.response.status == 422) {
          return null;
        }
        showMessage(errs, false);
      });
  };

  onRejectRequest = (data) => {
    putDataAccept('library_member', 'reject-request', data)
      .then(this.getListDocument())
      .then((res) => {
        showMessage(res.data.message, true);
        this.setState({
          errors: [],
          status: true
        });
        // history.goBack();
      })
      .catch((err) => {
        const errs = destructServerMessage(err);
        const errss = destructServerErrors(err);

        this.setState({
          errors: errss
        });
        if (err.response.status == 422) {
          return null;
        }
        showMessage(errs, false);
      });
  };

  handleInputChange = (e) => {
    let value = e.target.checked;
    if (value) {
      this.setState({
        // overviewFlg: 1
      });
    } else {
      this.setState({
        // overviewFlg: 0
      });
    }
  };

  getListDocument = async () => {
    const { page, perPage } = this.state;
    this.getListRequest({
      page,
      per_page: 20,
      keyword: this.keyword.search_text || ''
    });
  };

  changePage = (pageNumber) => {
    const { page } = this.state;
    if (pageNumber !== page) {
      this.getListRequest({
        page: pageNumber || 1,
        per_page: 20,
        keyword: this.keyword.search_text || ''
      });
      this.setState({ page: pageNumber });
    }
  };

  getListRequest = async (params) => {
    const { perPage } = 20;
    await getDataByParams('library_members/request', params)
      .then((res) => {
        this.setState({
          tableData: res?.data,
          paginate: {
            ...res.data.paginate,
            perPage
          },
          isLoading: false
        });
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  onChangeSearch = (e) => {
    clearTimeout(this.timer);
    const { page } = this.state;
    const { target } = e;
    const { name } = target;
    const value = target.value ? target.value : '';
    this.keyword = {
      ...this.keyword,
      [name]: value
    };
    this.timer = setTimeout(
      () =>
        this.getListRequest({
          page,
          per_page: 20,
          keyword: this.keyword.search_text || ''
        }),
      500
    );
    this.setState({
      page: 1
    });
  };

  onSubmitSearch = (e) => {
    e.preventDefault();
    clearTimeout(this.timer);
    const { page, perPage } = this.state;
    const { target } = e;
    const { name } = target;
    const value = target.value ? target.value : '';
    this.keyword = {
      ...this.keyword,
      [name]: value
    };
    this.timer = setTimeout(
      () =>
        this.getListDocument({
          page,
          per_page: perPage,
          keyword: this.keyword.search_text || ''
        }),
      500
    );
  };

  componentDidMount = () => {
    this.getListDocument();
  };
  render() {
    const { tableHeaders, tableData, paginate, isLoading } = this.state;
    return (
      <div className="body-right-bottom course-wrap">
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col-md-12 top-table-title">
              <p>Quản lý tư liệu </p>
            </div>
            <div className="col-md-1 top-table-border "></div>
            <div className="col-md-12 top-table-text">Danh sách yêu cầu</div>
          </div>

          <div className="row header-wrap">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <InputSearch
                onChange={this.onChangeSearch}
                onSubmitSearch={this.onSubmitSearch}
              />
              {/* <Can I="library_photo_add" a="library">
                <Link
                  to={{
                    pathname: '/libraries/new'
                    // query: { log_id: 4 }
                  }}
                  className="my-auto pb-2"
                >
                  <NewButton />
                </Link>
              </Can> */}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Table
                fixed
                tableHeaders={tableHeaders}
                tableData={tableData}
                tablePaginate={paginate}
                isLoading={isLoading}
              />
              <Modal />
              <Modal />
            </div>
            <div className="table-pagenatie ml-auto">
              <div className="paginate-wrapper">
                <Paginate paginate={paginate} changePage={this.changePage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
