import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Switch from 'react-switch';
import { withTranslation } from 'react-i18next';

import {
  GET_CLUB_BRANCH,
  GET_LIST_BRANCH,
  GET_LIST_INFO,
  NEW_DOCUMENT_REQUEST
} from '../../constants/config';
import {
  destructServerErrors,
  destructServerMessage
} from '../../helpers/error';
import {
  getDataByID,
  getTakenData,
  postData
} from '../../services/base_services';
import { showMessage } from '../../helpers/table';
import { LIMIT_POSITION } from '../../constants/position';

import Select from '../../component/common/Select';
import TextArea from '../../component/common/TextArea';
import InputText from '../../component/common/InputText';
import ButtonSave from '../../component/common/ButtonSave';
import TextEditor from '../../component/common/TextEditor';
import UploadImg from '../../component/common/UploadImg';
import TagsInput from '../../component/common/HashTags';
import GroupPhoto from '../../component/common/GroupPhoto';

class NewDocument extends Component {
  constructor(props) {
    super(props);
    this.documents = [];
    this.state = {
      status: false,
      errors: [],
      listBranch: [],
      listClub: [],
      showBranch: false,
      contentText: '',
      listDoc: [],
      defaultList: [],
      loadList: true,
      hashtag: [],
      is_show_new: false,
      position: LIMIT_POSITION,
      thumbnail: ''
    };
  }

  addNew = (e) => {
    e.preventDefault();
    const { contentText, showBranch, is_show_new } = this.state;
    const { user, match, history } = this.props;
    const { params } = match;
    const { id } = params;
    let formData = [];

    if (this.InfoCatalogId.value == 2) {
      if (this.branchId.value == 0) {
        this.setState({
          errors: {
            branch_id: [this.props.t('student.errorBranch')]
          }
        });
      } else {
        if (showBranch) {
          formData = {
            content: contentText,
            branch_id: this.branchId.value,
            club_id: this.clubId.value,
            info_catalog_id: this.InfoCatalogId.value,
            description: this.description.value,
            attach_files: this.documents,
            title: this.title.value,
            author: user.user.id,
            status: 1,
            is_show_new: is_show_new,
            hashtag: this.state.hashtag,
            position: this.position?.value,
            thumbnail: this.thumbnail?.value
          };
        } else {
          formData = {
            content: contentText,
            info_catalog_id: this.InfoCatalogId.value,
            description: this.description.value,
            attach_files: this.documents,
            title: this.title.value,
            author: user.user.id,
            status: 1,
            is_show_new: is_show_new,
            hashtag: this.state.hashtag,
            position: this.position?.value,
            thumbnail: this.thumbnail?.value
          };
        }
        if (
          Number.isInteger(Number(this.position.value)) &&
          Number(this.position.value) > 0
        ) {
          postData(NEW_DOCUMENT_REQUEST, formData)
            .then((res) => {
              showMessage(res.data.message, true);
              this.setState({
                errors: [],
                status: true
              });
              history.goBack();
            })
            .catch((err) => {
              const errs = destructServerMessage(err);
              const errss = destructServerErrors(err);
              this.setState({
                errors: errss
              });
              if (err.response.status == 422) {
                return null;
              }
              showMessage(errs, false);
            });
        } else {
          showMessage(this.props.t('branch.errorPosition'), false);
        }
      }
    } else {
      if (showBranch) {
        formData = {
          content: contentText,
          branch_id: this.branchId.value,
          club_id: this.clubId.value,
          info_catalog_id: this.InfoCatalogId.value,
          description: this.description.value,
          attach_files: this.documents,
          title: this.title.value,
          author: user.user.id,
          status: 1,
          is_show_new: is_show_new,
          hashtag: this.state.hashtag,
          position: this.position?.value,
          thumbnail: this.thumbnail?.value
        };
      } else {
        formData = {
          content: contentText,
          info_catalog_id: this.InfoCatalogId.value,
          description: this.description.value,
          attach_files: this.documents,
          title: this.title.value,
          author: user.user.id,
          status: 1,
          is_show_new: is_show_new,
          hashtag: this.state.hashtag,
          position: this.position?.value,
          thumbnail: this.thumbnail?.value
        };
      }
      if (
        Number.isInteger(Number(this.position.value)) &&
        Number(this.position.value) > 0
      ) {
        postData(NEW_DOCUMENT_REQUEST, formData)
          .then((res) => {
            showMessage(res.data.message, true);
            this.setState({
              errors: [],
              status: true
            });
            history.goBack();
          })
          .catch((err) => {
            const errs = destructServerMessage(err);
            const errss = destructServerErrors(err);
            this.setState({
              errors: errss
            });
            if (err.response.status == 422) {
              return null;
            }
            showMessage(errs, false);
          });
      } else {
        showMessage(this.props.t('branch.errorPosition'), false);
      }
    }
  };

  addDraft = (e) => {
    e.preventDefault();
    const { contentText, showBranch, is_show_new } = this.state;
    const { user, match, history } = this.props;
    const { params } = match;
    const { id } = params;
    let formData = [];
    if (this.InfoCatalogId.value == 2) {
      if (this.branchId.value == 0) {
        this.setState({
          errors: {
            branch_id: [this.props.t('document.error.branch_id')]
          }
        });
      } else {
        if (showBranch) {
          formData = {
            content: contentText,
            branch_id: this.branchId.value,
            club_id: this.clubId.value,
            info_catalog_id: this.InfoCatalogId.value,
            description: this.description.value,
            attach_files: this.documents,
            title: this.title.value,
            author: user.user.id,
            status: 0,
            is_show_new: is_show_new,
            hashtag: this.state.hashtag,
            position: this.position?.value,
            thumbnail: this.thumbnail?.value
          };
        } else {
          formData = {
            content: contentText,
            info_catalog_id: this.InfoCatalogId.value,
            description: this.description.value,
            attach_files: this.documents,
            title: this.title.value,
            author: user.user.id,
            status: 0,
            is_show_new: is_show_new,
            hashtag: this.state.hashtag,
            position: this.position?.value,
            thumbnail: this.thumbnail?.value
          };
        }
        if (
          Number.isInteger(Number(this.position.value)) &&
          Number(this.position.value) > 0
        ) {
          postData(NEW_DOCUMENT_REQUEST, formData)
            .then((res) => {
              showMessage(res.data.message, true);
              this.setState({
                errors: [],
                status: true
              });
              history.goBack();
            })
            .catch((err) => {
              const errs = destructServerMessage(err);
              const errss = destructServerErrors(err);
              this.setState({
                errors: errss
              });
              if (err.response.status == 422) {
                return null;
              }
              showMessage(errs, false);
            });
        } else {
          showMessage(this.props.t('branch.errorPosition'), false);
        }
      }
    } else {
      if (showBranch) {
        formData = {
          content: contentText,
          branch_id: this.branchId.value,
          club_id: this.clubId.value,
          info_catalog_id: this.InfoCatalogId.value,
          description: this.description.value,
          attach_files: this.documents,
          title: this.title.value,
          author: user.user.id,
          status: 0,
          is_show_new: is_show_new,
          hashtag: this.state.hashtag,
          position: this.position?.value,
          thumbnail: this.thumbnail?.value
        };
      } else {
        formData = {
          content: contentText,
          info_catalog_id: this.InfoCatalogId.value,
          description: this.description.value,
          attach_files: this.documents,
          title: this.title.value,
          author: user.user.id,
          status: 0,
          is_show_new: is_show_new,
          hashtag: this.state.hashtag,
          position: this.position?.value,
          thumbnail: this.thumbnail?.value
        };
      }
      if (
        Number.isInteger(Number(this.position.value)) &&
        Number(this.position.value) > 0
      ) {
        postData(NEW_DOCUMENT_REQUEST, formData)
          .then((res) => {
            showMessage(res.data.message, true);
            this.setState({
              errors: [],
              status: true
            });
            history.goBack();
          })
          .catch((err) => {
            const errs = destructServerMessage(err);
            const errss = destructServerErrors(err);
            this.setState({
              errors: errss
            });
            if (err.response.status == 422) {
              return null;
            }
            showMessage(errs, false);
          });
      } else {
        showMessage(this.props.t('branch.errorPosition'), false);
      }
    }
  };

  componentDidMount = async () => {
    await this.getList();
  };

  getList = async () => {
    const { location, user } = this.props;
    const id = location && location.query && location.query.log_id;
    const positionId = user && user.user && user.user.position_id;
    if (id == 2) {
      this.setState({
        showBranch: true
      });
    }
    await getTakenData(GET_LIST_BRANCH).then((res) => {
      this.setState({
        listBranch: res.data
      });
    });
    await getTakenData(GET_LIST_INFO).then((res) => {
      this.setState({
        listDoc: res.data
      });
    });
    if (positionId == 2 || positionId == 3) {
      const idBranchList = this.state.listBranch[0].id;
      await getDataByID(GET_CLUB_BRANCH, idBranchList).then((res) => {
        this.setState({
          listClub: res.data
        });
      });
    }
  };

  selectDoc = (e) => {
    const docId = e.target.value;
    if (docId === '2') {
      this.setState({
        showBranch: true,
        errors: []
      });
    } else {
      this.setState({
        showBranch: false,
        errors: []
      });
    }
  };

  selectBranch = async (e) => {
    const branchId = e.target.value;
    await getDataByID(GET_CLUB_BRANCH, branchId).then((res) => {
      this.setState({
        listClub: res.data
      });
    });
  };

  onChangeDocument = (data) => {
    this.documents = data;
  };

  changeEditer = (e, editor) => {
    const data = editor.getData();
    this.setState({
      contentText: data
    });
  };

  onChangePosition = (e) => {
    this.setState({ position: e });
    if (!Number.isInteger(Number(e)) || Number(e) <= 0) {
      this.setState({
        errors: {
          ...this.state.errors,
          position: [this.props.t('branch.errorPosition')]
        }
      });
    }
  };
  handleDisplayToHome = (e) => {
    this.setState({
      is_show_new: e
    });
  };

  handleTagsChange(newtags) {
    this.setState({ hashtag: newtags });
  }
  render() {
    const { t } = this.props;

    const {
      errors,
      listClub,
      listBranch,
      showBranch,
      listDoc,
      defaultList,
      loadList,
      is_show_new,
      hashtag,
      position,
      thumbnail
    } = this.state;
    const { history, location, user } = this.props;
    const positionId = user && user.user && user.user.position_id;
    const adminFlg = user && user.user && user.user.admin_flg;
    const id = location && location.query && location.query.log_id;
    const club_id = user && user.user && user.user.club_id;
    const listClubTmp = listClub.filter((item) => {
      return item.id == club_id;
    });

    return (
      <>
        <div className="body-right-bottom document-wrap">
          <div className="container-fluid container-shadow">
            <div className="row top-table">
              <div className="col-md-12 top-table-title">
                <p>{this.props.t('document.header')}</p>
              </div>
              <div className="col-md-1 top-table-border "></div>
              <div className="col-md-12 top-table-text">
                <p>{this.props.t('document.header')}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 form-title">
                <p>{this.props.t('document.headerFormAdd')}</p>
              </div>
              <div className="col-md-6">
                {positionId == 1 && !adminFlg ? (
                  <Select
                    label={this.props.t('document.articleType')}
                    className="form-control-custom input-form form-control-product mb-3 input-grey input-form-select input-form-select-custom"
                    name="info_catalog_id"
                    ref={(c) => {
                      this.InfoCatalogId = c;
                    }}
                    errors={errors}
                    disabled
                    key_value="id"
                    key_label="name"
                    data={listDoc}
                    onChange={this.selectDoc}
                    defaultValue={listDoc.find((item) => item.id === id)}
                    required={true}
                  />
                ) : (
                  <Select
                    label={this.props.t('document.articleType')}
                    className="form-control-custom input-form form-control-product mb-3 input-grey input-form-select"
                    name="info_catalog_id"
                    ref={(c) => {
                      this.InfoCatalogId = c;
                    }}
                    errors={errors}
                    key_value="id"
                    key_label="name"
                    include_blank={this.props.t('document.articleType')}
                    data={listDoc}
                    onChange={this.selectDoc}
                    defaultValue={listDoc.find((item) => item.id === id)}
                    required={true}
                  />
                )}
              </div>
            </div>
            {showBranch ? (
              <div className="row">
                <div className="col-md-6">
                  {positionId == 2 || positionId == 3 ? (
                    <Select
                      label={this.props.t('document.branchs')}
                      className="form-control-custom input-form form-control-product mb-3 input-grey input-form-select input-form-select-custom"
                      name="branch_id"
                      ref={(c) => {
                        this.branchId = c;
                      }}
                      disabled
                      errors={errors}
                      key_value="id"
                      key_label="name"
                      data={listBranch}
                      required={true}
                    />
                  ) : (
                    <Select
                      label={this.props.t('document.branchs')}
                      className="form-control-custom input-form form-control-product mb-3 input-grey input-form-select"
                      name="branch_id"
                      ref={(c) => {
                        this.branchId = c;
                      }}
                      errors={errors}
                      key_value="id"
                      key_label="name"
                      include_blank={this.props.t('document.branchs')}
                      data={listBranch}
                      onChange={this.selectBranch}
                      required={true}
                    />
                  )}
                </div>
                <div className="col-md-6">
                  {positionId == 3 ? (
                    <Select
                      label={this.props.t('document.clubs')}
                      className="form-control-custom input-form form-control-product mb-3 input-grey input-form-select input-form-select-custom"
                      name="club_id"
                      ref={(c) => {
                        this.clubId = c;
                      }}
                      disabled
                      errors={errors}
                      key_value="id"
                      key_label="name"
                      data={listClubTmp}
                    />
                  ) : (
                    <Select
                      label={this.props.t('document.clubs')}
                      className="form-control-custom input-form form-control-product mb-3 input-grey input-form-select"
                      name="club_id"
                      ref={(c) => {
                        this.clubId = c;
                      }}
                      errors={errors}
                      key_value="id"
                      key_label="name"
                      include_blank={this.props.t('document.clubs')}
                      data={listClub}
                    />
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
            <div className="row">
              <div className="col-md-12">
                <InputText
                  className="form-control input-form"
                  placeholder={this.props.t('document.title')}
                  name="title"
                  ref={(c) => {
                    this.title = c;
                  }}
                  label={this.props.t('document.title')}
                  errors={errors}
                  type="text"
                  required={true}
                />
              </div>
              <div className="col-md-12">
                <TextArea
                  className="form-control input-form"
                  placeholder={this.props.t('document.description')}
                  name="description"
                  ref={(c) => {
                    this.description = c;
                  }}
                  label={this.props.t('document.description')}
                  errors={errors}
                  type="textarea"
                />
              </div>
              <div className="col-md-12">
                <TextEditor
                  name="content"
                  label={this.props.t('document.content')}
                  errors={errors}
                  onChange={this.changeEditer}
                  required={true}
                />
              </div>
              {/* TODO: Chờ a Nghiệp */}
              {/* <div className="col-md-12">
                <label className="input-label">
                  {this.props.t('document.image')}
                </label>
                <UploadImg
                  label={this.props.t('document.image')}
                  name="main_branch_image"
                  title={this.props.t('document.changeImgBanner')}
                  onChange={this.onChangeDocument}
                  errors={[]}
                  default={this.documents}
                />
              </div> */}
              <div className="col-md-6">
                <GroupPhoto
                  label={this.props.t('document.banner')}
                  name="thumbnail"
                  title={this.props.t('document.changeImgBanner')}
                  errors={[]}
                  order={1}
                  required={true}
                  ref={(c) => {
                    this.thumbnail = c;
                  }}
                  defaultValue={thumbnail}
                />
              </div>
              <div className="col-md-12">
                <TagsInput
                  label={this.props.t('document.hashtagLabel')}
                  name={this.props.t('document.hashtag')}
                  value={this.state.hashtag}
                  onChange={this.handleTagsChange}
                />
              </div>
              <div className="col-md-6">
                <InputText
                  label={this.props.t('document.labelPosition')}
                  className="form-control input-form"
                  placeholder={this.props.t('document.position')}
                  errors={errors}
                  type="number"
                  name="position"
                  ref={(c) => {
                    this.position = c;
                  }}
                  defaultValue={position}
                  onChange={(e) => {
                    this.onChangePosition(e?.target?.value);
                  }}
                  required={true}
                />
              </div>
              <div className="col-md-6">
                <p className="label-banner-mobile">
                  {this.props.t('document.display')}
                </p>
                <Switch
                  onChange={this.handleDisplayToHome}
                  checked={is_show_new}
                />
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="mx-auto  my-5 d-flex flex-wrap">
                <ButtonSave
                  text={this.props.t('document.saveDraft')}
                  className="btn btn-draf"
                  onClick={this.addDraft}
                />
                <ButtonSave
                  onClick={this.addNew}
                  text={this.props.t('document.posts')}
                  className="btn btn-new"
                />
                <div onClick={history.goBack} onKeyPress={[]} role="button">
                  <ButtonSave
                    text={this.props.t('document.cancel')}
                    className="btn btn-cancel"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

NewDocument.propTypes = {
  history: PropTypes.func.isRequired,
  user: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(withTranslation()(NewDocument)));
