import React from 'react';
import './CustomRadioGroup.scss';

const CustomRadioGroup = ({ options, onChange, value, idPie, buttonStyle }) => {
  return (
    <div class="containers">
      <div className=" tabs">
        {options.map((option, index) => (
          <>
            <input
              id={`${idPie}-${index + 1}`}
              type="radio"
              value={option?.value}
              checked={value === option?.value}
              onChange={(e) => onChange(e.target.value)}
            />
            <label
              key={option?.value}
              for={`${idPie}-${index + 1}`}
              className=" tab"
            >
              {option?.label}
            </label>
          </>
        ))}
        <span class="glider"></span>
      </div>
    </div>
  );
};

export default CustomRadioGroup;
