/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import Can from '../../services/Can';
import { confirmDelete, showMessage } from '../../helpers/table';
import { fetchBranchs, deleteBranch, exportFile } from '../../actions/branch';
import { getTakenData, postDataWithParams } from '../../services/base_services';
import { PER_PAGE } from '../../constants/variable';
import {
  BASE_URL,
  GET_ALL_BRANCH,
  GET_GROUP_BRANCH_REQUEST
} from '../../constants/config';

import Table from '../../component/common/Table';
import ReactTooltip from 'react-tooltip';
import InputSearch from '../../component/common/InputSearch';
import Select from '../../component/common/Select';
import Paginate from '../../component/Paginate';
import NewButton from '../../component/common/NewButton';

import editIcon from '../../assets/images/icon/edit.svg';
import deleteIcon from '../../assets/images/icon/delete-kn.svg';
import detail from '../../assets/images/images/detail.svg';
import uploadIcon from '../../assets/images/icon/upload-icon.svg';

const Branch = (props) => {
  const { t } = useTranslation();
  const { user } = props;

  const [tableData, setTableData] = useState([]);
  const [paginate, setPaginate] = useState([]);

  const [area, setArea] = useState([]);
  const [filterBranch, setFilterBranch] = useState({
    page: 1,
    per_page: 10,
    keyword: '',
    area_id: ''
  });

  useEffect(() => {
    getTakenData(GET_GROUP_BRANCH_REQUEST).then((res) => {
      setArea(res?.data);
    });
  }, []);

  const renderExcel = () => {
    exportFile().then((res) => {
      if (res?.status === 'OK') {
        window.open(
          `${BASE_URL}download?file=/uploads/branchs.csv&name=branchs`
        );
      }
    });
  };

  const tableHeaders = [
    {
      label: t('branch.stt'),
      index: 'stt',
      option: {
        className: 'text-center position-relative'
      },
      callback: null
    },
    {
      label: t('branch.nameBranch'),
      index: null,
      callback: (data) => {
        if (data && data.name !== null)
          return (
            <div>
              <span>{data && data.name}</span>
            </div>
          );
        return (
          <div className="text-center">
            <span> - </span>
          </div>
        );
      }
    },
    {
      label: t('branch.manageBranch'),
      index: null,
      callback: (data) => {
        if (data && data.branch_leader)
          return (
            <div>
              <span>{data && data.branch_leader}</span>
            </div>
          );
        return (
          <div className="text-center">
            <span> - </span>
          </div>
        );
      }
    },
    {
      label: t('branch.club'),
      index: null,
      option: {
        className: 'text-center'
      },
      callback: (data) => {
        if (data && data.num_club !== null)
          return (
            <div>
              <span>{data && data.num_club}</span>
            </div>
          );
        return (
          <div className="text-center">
            <span> - </span>
          </div>
        );
      }
    },
    {
      label: t('branch.student'),
      index: null,
      option: {
        className: 'text-center'
      },
      callback: (data) => {
        if (data && data.num_member !== null)
          return (
            <div>
              <span>{data && data.num_member}</span>
            </div>
          );
        return (
          <div className="text-center">
            <span> - </span>
          </div>
        );
      }
    },
    {
      label: t('branch.action'),
      index: null,
      option: {
        className: 'text-center fixed-collumn'
      },
      callback: (data) => {
        if (data.id) {
          return (
            <div className="btn-group">
              <span
                data-tip="Chi Tiết Phân Đường"
                className="link-action ml-2 mr-2"
              >
                <Link
                  className="d-block"
                  role="button"
                  tabIndex={0}
                  onKeyPress={() => {}}
                  to={`/statistical/branch/detail/${data.id}`}
                >
                  <img src={detail} alt="edit" className="btn-icon" />
                </Link>
              </span>
              <Can I="branch_edit" a="branch">
                <span
                  data-tip="Chỉnh Sửa Phân Đường"
                  className="link-action ml-2 mr-2"
                >
                  <Link
                    role="button"
                    className="d-block"
                    tabIndex={0}
                    onKeyPress={() => {}}
                    to={`/statistical/branch/edit/${data.id}`}
                  >
                    <img src={editIcon} alt="edit" className="btn-icon" />
                  </Link>
                </span>
              </Can>
              <Can I="branch_del" a="branch">
                <span data-tip="Xóa Phân Đường" className="link-action ml-2">
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => removeBranch(data.id)}
                    onKeyPress={() => {}}
                  >
                    <img alt="delete" src={deleteIcon} className="btn-icon" />
                  </div>
                </span>
              </Can>
              <ReactTooltip
                className="tooltip-button"
                place="bottom"
                effect="float"
              />
            </div>
          );
        }
        return '';
      }
    }
  ];

  const removeBranch = (id) => {
    confirmDelete().then((res) => {
      if (res.isConfirmed) {
        deleteBranch(id).then(() => {
          fetchBranchs(paginate.current_page).then((res) => {
            setTableData(res.data);
            setPaginate({ ...res.paginate, perPage: PER_PAGE });
            setFilterBranch({ ...filterBranch, page: 1, keyword: '' });
          });
        });
      }
    });
  };

  const postFilterBranchs = (param) => {
    postDataWithParams(GET_ALL_BRANCH, param)
      .then((res) => {
        setTableData(res?.data?.data);
        setPaginate({ ...res?.data.paginate, perPage: PER_PAGE });
      })
      .catch((error) => showMessage(`${t('dashboard.error')} ${error}`), false);
  };

  useEffect(() => {
    postFilterBranchs(filterBranch);
  }, [filterBranch]);

  const handleChangeArea = (e) => {
    setFilterBranch({ ...filterBranch, page: 1, area_id: e.target.value });
    postFilterBranchs({ ...filterBranch, page: 1, area_id: e.target.value });
  };

  const handleChangeSearch = (e) => {
    setFilterBranch({ ...filterBranch, page: 1, keyword: e.target.value });
    postDataWithParams({ ...filterBranch, page: 1, keyword: e.target.value });
  };

  const handleChangePage = (pageNumber) => {
    if (pageNumber !== paginate.current_page) {
      setPaginate({ ...paginate });
      setFilterBranch({ ...filterBranch, page: pageNumber });
      postDataWithParams({ ...filterBranch, page: pageNumber });
    }
  };

  return (
    <>
      <section className="body-right-bottom course-wrap">
        <div className="container-fluid content">
          <div className="row top-table">
            <div className="col-md-12 top-table-title">
              <p>{t('branch.header')}</p>
            </div>
            <div className="col-md-1 top-table-border "></div>
            <div className="col-md-12 top-table-text">
              {t('branch.headerForm')}
            </div>
          </div>

          <div className="row header-wrap">
            <div className="col-sm-12 col-md-3 col-lg-4">
              {user?.position_id == 1 && (
                <InputSearch
                  value={filterBranch.keyword}
                  onChange={(e) => handleChangeSearch(e)}
                />
              )}
            </div>
            <div className="col-sm-12 col-md-3 col-lg-4">
              {user?.position_id == 1 && (
                <Select
                  className="form-control-select input-form col-md-3"
                  name="name"
                  key_value="id"
                  key_label="name"
                  data={area}
                  errors={[]}
                  value={filterBranch?.area_id}
                  include_blank={t('branch.branch')}
                  onChange={(e) => {
                    handleChangeArea(e);
                  }}
                />
              )}
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 content__flex-end">
              {user?.position_id == 1 && (
                <button className="donate-list" onClick={renderExcel}>
                  Xuất file
                  <ReactSVG src={uploadIcon} className="naviga-icon" />
                </button>
              )}
              <Can I="branch_add" a="branch">
                <Link to="/statistical/branch/new">
                  <NewButton />
                </Link>
              </Can>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Table
                tableData={tableData}
                tableHeaders={tableHeaders}
                tablePaginate={paginate}
              />
            </div>
            <div className="table-pagenatie ml-auto">
              <div className="paginate-wrapper">
                <Paginate paginate={paginate} changePage={handleChangePage} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state?.user?.user
  };
};
Branch.propTypes = {
  history: PropTypes.isRequired
};
export default connect(mapStateToProps, null)(withRouter(Branch));
