import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next/';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

import { Type } from '../../../constants/dashboard';

import CustomRadioGroup from '../../../component/common/CustomRadiogroup';

import '../LineChart/LineChart.scss';

function LineChartCommon({ dataLineChart }) {
  const { t } = useTranslation();

  const [filter, setFilter] = useState('user');
  const [datas, setDatas] = useState([]);
  const [timeCurrent, setTimeCurrent] = useState('Tuần này');
  const [timePrevious, setTimePrevious] = useState('Tuần trước');

  const date = new Date();

  const options = [
    { label: t('dashboard.student'), value: 'user' },
    { label: t('dashboard.posts'), value: 'information' },
    { label: t('dashboard.activity'), value: 'activate' }
  ];

  const customTime = (key, type) => {
    if (type == Type.WEEK) {
      return `${t(`dashboard.${key}`)}`;
    } else if (type == Type.MONTH) {
      return `${t('dashboard.day')} ${key}`;
    } else if (type == Type.YEAR) {
      return `${t('dashboard.month')} ${key}`;
    } else if (type == Type.ALL) {
      return `${t('dashboard.year')} ${key}`;
    }
  };
  useEffect(() => {
    let timeCurrent1 = t('dashboard.currentWeek');
    let timePrevious1 = t('dashboard.previousWeek');

    switch (dataLineChart?.type) {
      case 'MONTH':
        timeCurrent1 = `${t('dashboard.month')} ${date.getMonth() + 1}`;
        timePrevious1 = `${t('dashboard.month')} ${date.getMonth()}`;

        break;
      case 'YEAR':
        timeCurrent1 = `${t('dashboard.year')}  ${date.getFullYear()}`;
        timePrevious1 = `${t('dashboard.year')} ${date.getFullYear() - 1}`;

        break;
      case 'ALL':
        timeCurrent1 = `${t('dashboard.all')} `;
        timePrevious1 = `${t('dashboard.year')} ${date.getFullYear() - 1}`;

        break;
      default:
        timeCurrent1 = `${t('dashboard.curentWeek')}`;
        timePrevious1 = `${t('dashboard.previousWeek')}`;
    }

    const dataChartForm =
      dataLineChart?.chartForm?.[filter] &&
      Object.entries(dataLineChart?.chartForm?.[filter])?.map(
        ([key1, value1]) => {
          return {
            name: customTime(key1, dataLineChart?.type),
            [timeCurrent1]: value1,
            [timePrevious1]:
              dataLineChart?.type !== 'ALL'
                ? Object.entries(dataLineChart?.chartFormPre?.[filter])
                    ?.map(([key, value]) => ({
                      name: key,
                      [timePrevious1]: value
                    }))
                    ?.find((it) => it.name == key1)?.[`${timePrevious1}`] || 0
                : 0
          };
        }
      );

    setDatas(dataChartForm);
  }, [filter, dataLineChart]);

  useEffect(() => {
    if (datas?.length) {
      const a = datas?.[0];

      setTimeCurrent(Object.keys(a)?.[1]);
      setTimePrevious(Object.keys(a)?.[2]);
    }
  }, [dataLineChart, datas]);

  const handleChange = (value) => {
    setFilter(value);
  };

  const CustomTick = (props) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <svg
          width="10"
          height="9"
          viewBox="0 0 10 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="4.86572" cy="4.5" r="4" stroke="#A0A3BD" />
        </svg>
        <text
          x={0}
          y={10}
          dy={16}
          textAnchor="middle"
          fill="#B3B3B3"
          fontSize={15}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: '#fff',
            padding: '5px 45px 5px 5px',
            border: '1px solid #ccc'
          }}
        >
          {dataLineChart.type != 'ALL' && (
            <p
              className="label"
              style={{ color: payload[0].color, marginBottom: 0 }}
            >{`${timePrevious}: ${payload[0].value}`}</p>
          )}
          <p
            className="label"
            style={{ color: payload[1].color, marginBottom: 0 }}
          >{`${
            dataLineChart.type != 'ALL' ? timeCurrent : payload[0].payload.name
          }: ${payload[1]?.value}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="linechar linechar-wap">
      <div className=" linechar-filter  ">
        <CustomRadioGroup
          idPie="belt"
          options={options}
          value={filter}
          onChange={handleChange}
          buttonStyle="buttonStyle"
        />

        <div className="linechar-legen ">
          {dataLineChart.type !== 'ALL' && (
            <div className="linechar-legen-items">
              <span className="circle circle-blue"></span>
              <p>{timePrevious}</p>
            </div>
          )}
          <div className="linechar-legen-items">
            <span className="circle circle-red"></span>
            <p>{timeCurrent}</p>
          </div>
        </div>
      </div>

      <div className="linechar-item">
        <ResponsiveContainer width="100%" height={290}>
          <LineChart
            data={datas}
            height={180}
            axisLine={false}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 10
            }}
          >
            <CartesianGrid horizontal={false} vertical={true} />
            <XAxis
              dataKey="name"
              axisLine={false}
              tick={<CustomTick />}
              tickLine={false}
            />
            <YAxis
              tickFormatter={(tick) => (tick === 0 ? '' : tick)}
              tick={{ fontSize: 15, fill: '#6E7191' }}
              tickLine={false}
              axisLine={false}
              tickMargin={30}
            />
            <Tooltip content={<CustomTooltip />} />
            <Line
              type="monotone"
              dataKey={timePrevious}
              stroke={dataLineChart.type === 'ALL' ? ' #FFFFFF80' : '#2D9CDB'}
              activeDot={{ r: 8 }}
              legendType={'none'}
              dot={false}
              strokeWidth={3}
            />
            <Line
              type="monotone"
              dataKey={timeCurrent}
              stroke={'#FF5B5B'}
              dot={false}
              activeDot={{ r: 8 }}
              strokeWidth={3}
              legendType={'none'}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default LineChartCommon;
