import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import { withTranslation } from 'react-i18next';

import {
  GET_CLUB_BRANCH,
  GET_DOCUMENT_REQUEST,
  GET_LIST_BRANCH,
  GET_LIST_INFO,
  UPDATE_DOCUMENT_REQUEST
} from '../../constants/config';
import {
  destructServerErrors,
  destructServerMessage
} from '../../helpers/error';
import { showMessage } from '../../helpers/table';
import {
  getDataByID,
  getTakenData,
  putData
} from '../../services/base_services';
import { LIMIT_POSITION } from '../../constants/position';

import Select from '../../component/common/Select';
import TextArea from '../../component/common/TextArea';
import InputText from '../../component/common/InputText';
import TextEditor from '../../component/common/TextEditor';
import UploadImg from '../../component/common/UploadImg';
import ButtonSave from '../../component/common/ButtonSave';
import GroupFileCasorel from '../../component/common/GroupFileCasorel';
import TagsInput from '../../component/common/HashTags';
import GroupPhoto from '../../component/common/GroupPhoto';

class EditDocument extends Component {
  constructor(props) {
    super(props);
    this.documents = [];
    this.state = {
      isLoading: true,
      status: false,
      errors: [],
      listBranch: [],
      listClub: [],
      showBranch: false,
      contentText: '',
      defaultList: [],
      data: [],
      defaultbranch: [],
      defaultClub: [],
      listDoc: [],
      hashtag: [],
      is_show_new: false,
      position: LIMIT_POSITION,
      thumbnail: ''
    };
  }

  addNew = (e) => {
    e.preventDefault();
    const { contentText, showBranch, is_show_new } = this.state;
    const { user, match, history } = this.props;
    const { params } = match;
    const { id } = params;
    let formData = [];

    if (this.infoCatalogId?.value == 2) {
      if (this.branchId?.value == 0) {
        this.setState({
          errors: {
            branch_id: [this.props.t('student.errorBranch')]
          }
        });
      } else {
        if (showBranch) {
          formData = {
            content: contentText,
            branch_id: this.branchId?.value,
            club_id: this.clubId?.value,
            info_catalog_id: this.infoCatalogId?.value,
            description: this.description?.value,
            attach_files: this.documents,
            title: this.title?.value,
            author: user?.user?.id,
            status: 1,
            hashtag: this.state?.hashtag,
            is_show_new: is_show_new,
            position: this.position?.value,
            thumbnail: this.thumbnail?.value
          };
        } else {
          formData = {
            content: contentText,
            info_catalog_id: this.infoCatalogId.value,
            description: this.description.value,
            attach_files: this.documents,
            title: this.title.value,
            author: user.user.id,
            status: 1,
            hashtag: this.state.hashtag,
            is_show_new: is_show_new,
            position: this.position?.value,
            thumbnail: this.thumbnail?.value
          };
        }
        if (
          Number.isInteger(Number(this.position.value)) &&
          Number(this.position.value) > 0
        ) {
          putData(UPDATE_DOCUMENT_REQUEST, id, formData)
            .then((res) => {
              showMessage(res.data.message, true);
              this.setState({
                errors: [],
                status: true
              });
              history.goBack();
            })
            .catch((err) => {
              const errs = destructServerMessage(err);
              const errss = destructServerErrors(err);
              this.setState({
                errors: errss
              });
              if (err.response.status == 422) {
                return null;
              }
              showMessage(errs, false);
            });
        } else {
          showMessage(this.props.t('branch.errorPosition'), false);
        }
      }
    } else {
      if (showBranch) {
        formData = {
          content: contentText,
          branch_id: this.branchId.value,
          club_id: this.clubId.value,
          info_catalog_id: this.infoCatalogId.value,
          description: this.description.value,
          attach_files: this.documents,
          title: this.title.value,
          author: user.user.id,
          status: 1,
          hashtag: this.state.hashtag,
          is_show_new: is_show_new,
          position: this.position?.value,
          thumbnail: this.thumbnail?.value
        };
      } else {
        formData = {
          content: contentText,
          info_catalog_id: this.infoCatalogId.value,
          description: this.description.value,
          attach_files: this.documents,
          title: this.title.value,
          author: user.user.id,
          hashtag: this.state.hashtag,
          status: 1,
          is_show_new: is_show_new,
          position: this.position?.value,
          thumbnail: this.thumbnail?.value
        };
      }
      if (
        Number.isInteger(Number(this.position.value)) &&
        Number(this.position.value) > 0
      ) {
        putData(UPDATE_DOCUMENT_REQUEST, id, formData)
          .then((res) => {
            showMessage(res.data.message, true);
            this.setState({
              errors: [],
              status: true
            });
            history.goBack();
          })
          .catch((err) => {
            const errs = destructServerMessage(err);
            const errss = destructServerErrors(err);
            this.setState({
              errors: errss
            });
            if (err.response.status == 422) {
              return null;
            }
            showMessage(errs, false);
          });
      } else {
        showMessage(this.props.t('branch.errorPosition'), false);
      }
    }
  };

  addDraft = (e) => {
    e.preventDefault();
    const { contentText, showBranch, is_show_new } = this.state;
    const { user, match, history } = this.props;
    const { params } = match;
    const { id } = params;
    let formData = [];
    if (this.infoCatalogId.value == 2) {
      if (this.branchId.value == 0) {
        this.setState({
          errors: {
            branch_id: [this.props.t('document.error.branch_id')]
          }
        });
      } else {
        if (showBranch) {
          formData = {
            content: contentText,
            branch_id: this.branchId.value,
            club_id: this.clubId.value,
            info_catalog_id: this.infoCatalogId.value,
            description: this.description.value,
            attach_files: this.documents,
            title: this.title.value,
            author: user.user.id,
            status: 0,
            hashtag: this.state.hashtag,
            is_show_new: is_show_new,
            position: this.position?.value,
            thumbnail: this.thumbnail?.value
          };
        } else {
          formData = {
            content: contentText,
            info_catalog_id: this.infoCatalogId.value,
            description: this.description.value,
            attach_files: this.documents,
            title: this.title.value,
            author: user.user.id,
            status: 0,
            hashtag: this.state.hashtag,
            is_show_new: is_show_new,
            position: this.position?.value,
            thumbnail: this.thumbnail?.value
          };
        }
        if (
          Number.isInteger(Number(this.position.value)) &&
          Number(this.position.value) > 0
        ) {
          putData(UPDATE_DOCUMENT_REQUEST, id, formData)
            .then((res) => {
              showMessage(res.data.message, true);
              this.setState({
                errors: [],
                status: true
              });
              history.goBack();
            })
            .catch((err) => {
              const errs = destructServerMessage(err);
              const errss = destructServerErrors(err);
              this.setState({
                errors: errss
              });
              if (err.response.status == 422) {
                return null;
              }
              showMessage(errs, false);
            });
        } else {
          showMessage(this.props.t('branch.errorPosition'), false);
        }
      }
    } else {
      if (showBranch) {
        formData = {
          content: contentText,
          branch_id: this.branchId.value,
          club_id: this.clubId.value,
          info_catalog_id: this.infoCatalogId.value,
          description: this.description.value,
          attach_files: this.documents,
          title: this.title.value,
          author: user.user.id,
          status: 0,
          hashtag: this.state.hashtag,
          is_show_new: is_show_new,
          position: this.position?.value,
          thumbnail: this.thumbnail?.value
        };
      } else {
        formData = {
          content: contentText,
          info_catalog_id: this.infoCatalogId.value,
          description: this.description.value,
          attach_files: this.documents,
          title: this.title.value,
          author: user.user.id,
          status: 0,
          hashtag: this.state.hashtag,
          is_show_new: is_show_new,
          position: this.position?.value,
          thumbnail: this.thumbnail?.value
        };
      }
      if (
        Number.isInteger(Number(this.position.value)) &&
        Number(this.position.value) > 0
      ) {
        putData(UPDATE_DOCUMENT_REQUEST, id, formData)
          .then((res) => {
            showMessage(res.data.message, true);
            this.setState({
              errors: [],
              status: true
            });
            history.goBack();
          })
          .catch((err) => {
            const errs = destructServerMessage(err);
            const errss = destructServerErrors(err);
            this.setState({
              errors: errss
            });
            if (err.response.status == 422) {
              return null;
            }
            showMessage(errs, false);
          });
      } else {
        showMessage(this.props.t('branch.errorPosition'), false);
      }
    }
  };

  componentDidMount = async () => {
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    if (id) {
      await getDataByID(GET_DOCUMENT_REQUEST, id)
        .then((res) => {
          this.setState({
            data: res.data,
            contentText: res.data.content,
            isLoading: false,
            is_show_new: res?.data?.is_show_new || false,
            position: res?.data?.position,
            hashtag: res?.data?.hashtag || [],
            thumbnail: res?.data?.thumbnail
          });
          if (res.data.info_catalog_id === 2) {
            this.setState({
              showBranch: true
            });
          }

          return Promise.resolve({ res });
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    }
    this.getList();
  };

  getList = async () => {
    const { data } = this.state;
    await getTakenData(GET_LIST_BRANCH).then((res) => {
      this.setState({
        listBranch: res.data,
        defaultbranch: res.data.find((item) => item.id === data.branch_id)
      });
    });
    await getTakenData(GET_LIST_INFO).then((res) => {
      this.setState({
        listDoc: res.data,
        defaultList: res.data.find((item) => item.id === data.info_catalog_id)
      });
    });
    await getDataByID(GET_CLUB_BRANCH, data.branch_id).then((res) => {
      this.setState({
        listClub: res.data,
        defaultClub: res.data.find((item) => item.id === data.club_id)
      });
    });
  };

  selectDoc = (e) => {
    const docId = e.target.value;
    if (docId === '2') {
      this.setState({
        showBranch: true,
        errors: []
      });
    } else {
      this.setState({
        showBranch: false,
        errors: []
      });
    }
  };

  selectBranch = async (e) => {
    const branchId = e.target.value;
    await getDataByID(GET_CLUB_BRANCH, branchId).then((res) => {
      this.setState({
        listClub: res.data
      });
    });
  };

  onChangeDocument = (data) => {
    this.documents = data;
  };

  changeEditer = (e, editor) => {
    const data = editor.getData();
    this.setState({
      contentText: data
    });
  };

  handleDisplayToHome = (e) => {
    this.setState({
      is_show_new: e
    });
  };

  onChangePosition = (e) => {
    this.setState({ position: e });
    if (!Number.isInteger(Number(e)) || Number(e) <= 0) {
      this.setState({
        errors: {
          ...this.state.errors,
          position: [this.props.t('branch.errorPosition')]
        }
      });
    }
  };

  handleTagsChange(newtags) {
    this.setState({ hashtag: newtags });
  }

  render() {
    const { t } = this.props;

    const {
      errors,
      listClub,
      listBranch,
      showBranch,
      listDoc,
      data,
      defaultList,
      isLoading,
      defaultbranch,
      defaultClub,
      is_show_new,
      hashtag,
      handleDisplayToHome,
      position,
      thumbnail
    } = this.state;
    const { history, user } = this.props;
    const positionId = user && user.user && user.user.position_id;
    const club_id = user && user.user && user.user.club_id;
    const listClubTmp = listClub.filter((item) => {
      return item.id == club_id;
    });
    const adminFlg = user && user.user && user.user.admin_flg;
    return isLoading ? (
      ''
    ) : (
      <>
        <div className="body-right-bottom">
          <div className="container-fluid container-shadow">
            <div className="row top-table">
              <div className="col-md-12 top-table-title">
                <p>{this.props.t('document.header')}</p>
              </div>
              <div className="col-md-1 top-table-border "></div>
              <div className="col-md-12 top-table-text">
                {' '}
                <p>{this.props.t('document.header')}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 form-title">
                <p>{this.props.t('document.edit')} </p>
              </div>
              <div className="col-md-6">
                <InputText
                  className="form-control input-form"
                  label={this.props.t('document.author')}
                  errors={errors}
                  type="text"
                  defaultValue={data && data.user && data.user.name}
                  disabled
                />
              </div>
              <div className="col-md-6">
                <InputText
                  className="form-control input-form"
                  label={this.props.t('document.positions')}
                  errors={errors}
                  type="text"
                  defaultValue={data && data.user && data.user.position_name}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                {positionId == 1 && !adminFlg ? (
                  <Select
                    label={this.props.t('document.articleType')}
                    className="form-control-select input-form form-control-product mb-3 input-grey input-form-select input-form-select-custom"
                    name="info_catalog_id"
                    ref={(c) => {
                      this.infoCatalogId = c;
                    }}
                    disabled
                    errors={errors}
                    key_value="id"
                    key_label="name"
                    data={listDoc}
                    onChange={this.selectDoc}
                    defaultValue={defaultList}
                    required={true}
                  />
                ) : (
                  <Select
                    label={this.props.t('document.articleType')}
                    className="form-control-custom input-form form-control-product mb-3 input-grey input-form-select"
                    name="info_catalog_id"
                    ref={(c) => {
                      this.infoCatalogId = c;
                    }}
                    errors={errors}
                    key_value="id"
                    key_label="name"
                    include_blank={this.props.t('document.articleType')}
                    data={listDoc}
                    onChange={this.selectDoc}
                    defaultValue={defaultList}
                  />
                )}
              </div>
            </div>
            {showBranch ? (
              <div className="row">
                <div className="col-md-6">
                  {positionId == 2 || positionId == 3 ? (
                    <Select
                      label={this.props.t('document.branchs')}
                      className="form-control-custom input-form form-control-product mb-3 input-grey input-form-select input-form-select-custom"
                      name="branch_id"
                      ref={(c) => {
                        this.branchId = c;
                      }}
                      disabled
                      errors={errors}
                      key_value="id"
                      key_label="name"
                      data={listBranch}
                      onChange={this.selectBranch}
                      defaultValue={defaultbranch}
                    />
                  ) : (
                    <Select
                      label={this.props.t('document.branchs')}
                      className="form-control-custom input-form form-control-product mb-3 input-grey input-form-select"
                      name="branch_id"
                      ref={(c) => {
                        this.branchId = c;
                      }}
                      errors={errors}
                      key_value="id"
                      key_label="name"
                      include_blank={this.props.t('document.branchs')}
                      data={listBranch}
                      onChange={this.selectBranch}
                      defaultValue={defaultbranch}
                    />
                  )}
                </div>
                <div className="col-md-6">
                  {positionId == 3 ? (
                    <Select
                      label={this.props.t('document.clubs')}
                      className="form-control-custom input-form form-control-product mb-3 input-grey input-form-select input-form-select-custom"
                      name="club_id"
                      ref={(c) => {
                        this.clubId = c;
                      }}
                      disabled
                      errors={errors}
                      key_value="id"
                      key_label="name"
                      data={listClubTmp}
                      defaultValue={defaultClub}
                    />
                  ) : (
                    <Select
                      label={this.props.t('document.clubs')}
                      className="form-control-custom input-form form-control-product mb-3 input-grey input-form-select"
                      name="club_id"
                      ref={(c) => {
                        this.clubId = c;
                      }}
                      errors={errors}
                      key_value="id"
                      key_label="name"
                      include_blank={this.props.t('document.clubs')}
                      data={listClub}
                      defaultValue={defaultClub}
                    />
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
            <div className="row">
              <div className="col-md-12">
                <InputText
                  className="form-control input-form"
                  placeholder={this.props.t('document.title')}
                  name="title"
                  ref={(c) => {
                    this.title = c;
                  }}
                  label={this.props.t('document.title')}
                  errors={errors}
                  type="text"
                  defaultValue={data && data.title}
                  required={true}
                />
              </div>
              <div className="col-md-12">
                <TextArea
                  className="form-control input-form"
                  placeholder={this.props.t('document.description')}
                  name="description"
                  ref={(c) => {
                    this.description = c;
                  }}
                  label={this.props.t('document.description')}
                  errors={errors}
                  defaultValue={data && data.description}
                  type="textarea"
                />
              </div>
              <div className="col-md-12">
                <TextEditor
                  name="content"
                  label={this.props.t('document.content')}
                  errors={errors}
                  data={data && data.content}
                  onChange={this.changeEditer}
                  required={true}
                />
              </div>

              {/* <div className="col-md-12">
                <label className="input-label">
                  {' '}
                  {this.props.t('document.image')}
                </label>
                <UploadImg
                  label={this.props.t('document.image')}
                  name="attach_files"
                  title={this.props.t('document.changeImgBanner')}
                  onChange={this.onChangeDocument}
                  errors={[]}
                  default={data && data.attach_files}
                />
              </div> */}
              <div className="col-md-6">
                <GroupPhoto
                  label={this.props.t('document.banner')}
                  name="thumbnail"
                  title={this.props.t('document.changeImgBanner')}
                  errors={[]}
                  order={1}
                  required={true}
                  ref={(c) => {
                    this.thumbnail = c;
                  }}
                  defaultValue={thumbnail}
                />
              </div>
              <div className="col-md-12">
                <TagsInput
                  label={this.props.t('document.hashtagLabel')}
                  name={this.props.t('document.hashtag')}
                  value={this.state.hashtag}
                  onChange={this.handleTagsChange}
                />
              </div>
              <div className="col-md-6">
                <InputText
                  label={this.props.t('document.labelPosition')}
                  className="form-control input-form"
                  placeholder={this.props.t('document.position')}
                  errors={errors}
                  type="number"
                  name="position"
                  ref={(c) => {
                    this.position = c;
                  }}
                  defaultValue={position}
                  onChange={(e) => {
                    this.onChangePosition(e?.target?.value);
                  }}
                  required={true}
                />
              </div>

              <div className="col-md-6">
                <p className="label-banner-mobile">
                  {' '}
                  {this.props.t('document.display')}
                </p>
                <Switch
                  onChange={this.handleDisplayToHome}
                  checked={is_show_new}
                />
              </div>
            </div>

            <div className="row justify-content-center">
              <div className=" d-flex mx-auto my-5">
                <ButtonSave
                  text={this.props.t('document.saveDraft')}
                  className="btn btn-draf"
                  onClick={this.addDraft}
                />
                <ButtonSave
                  onClick={this.addNew}
                  text={this.props.t('document.save')}
                  className="btn btn-new ml-0"
                />
                <div
                  onClick={history.goBack}
                  onKeyPress={[]}
                  role="button"
                  tabIndex={0}
                >
                  <ButtonSave
                    text={this.props.t('document.cancel')}
                    className="btn btn-cancel"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

EditDocument.propTypes = {
  history: PropTypes.func.isRequired,
  user: PropTypes.func.isRequired,
  match: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(withTranslation()(EditDocument)));
