export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_IMG = process.env.REACT_APP_BASE_IMG;
export const BASE_URL_SITE_REGISTER =
  process.env.REACT_APP_BASE_URL_SITE_REGISTER;

export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;
// login
export const LOGIN = 'auth/login';
export const LOGOUT_URL = 'auth/sign-out';
export const GET_USER_URL = 'user';

// user
export const CHANGE_PASSWORD = 'user/change-pass';
export const UPDATE_USER_URL = 'user';
export const LIST_STUDENT_REQUEST = 'users';
export const DELETE_STUDENT_REQUEST = 'user';
export const NEW_STUDENT_REQUEST = 'user';
export const GET_STUDENT_INFO = 'user';
export const UPDATE_STUDENT_REQUEST = 'user';
export const UPDATE_ROLE_STUDENT_REQUEST = 'userrole';
export const UPDATE_USER_APP = 'club_member';
export const ACCEPT_USER = 'accept-user';

// clb
export const GET_LIST_CLUB = 'clubs';
export const GET_LIST_ALL_CLUB = 'allclub';
export const GET_LIST_BRANCH = 'allbranch';
export const GET_CLUB_BRANCH = 'getclubs';
export const NEW_CLUB_REQUEST = 'club';
export const GET_CLB_DETAIL = 'club';
export const DELETE_CLB = 'club';
export const UPDATE_CLB = 'club';
export const GET_LIST_MEMBER_CLUB = 'allmembers';
export const DELETE_MEMBER_CLB = 'user';

// img
export const UPLOAD_FILE = 'upload/files';
export const UPLOAD_URL = 'upload/image';

// document
export const LIST_DOCUMENT_REQUEST = 'informations';
export const DELETE_DOCUMENT_REQUEST = 'information';
export const NEW_DOCUMENT_REQUEST = 'information';
export const GET_DOCUMENT_REQUEST = 'information';
export const UPDATE_DOCUMENT_REQUEST = 'information';
export const POST_DOCUMENT_REQUEST = 'info/pushlish';
export const REJECT_DOCUMENT_REQUEST = 'info/reject';
export const GET_LIST_INFO = 'infocatalogs';

// comment
export const GET_COMMENT_REQUEST = 'comment';
export const NEW_COMMENT_REQUEST = 'comment';
export const DELETE_COMMENT_REQUEST = 'comment';

// libraries
export const LIST_LIBRARY_REQUEST = 'libraries';
export const DELETE_LIBRARY_REQUEST = 'library';
export const NEW_LIBRARY_REQUEST = 'library';
export const GET_LIBRARY_REQUEST = 'library';
export const UPDATE_LIBRARY_REQUEST = 'library';
export const GET_LIST_CATALOG = 'catalogs';
export const POST_LIBRARY_REQUEST = 'lib/pushlish';
export const GET_LIST_CATEGORY = 'categories';
export const NEW_TITLE_CATEGORY = 'category';
export const UPDATE_TITLE_CATEGORY = 'category';
export const DELETE_TITLE_CATEGORY = 'category';

// permission
export const GET_LIST_ROLES_EXAMPLE = 'roles';
export const GET_LIST_PERMISSION = 'permissions';
export const NEW_ROLE_REQUEST = 'role';
export const DELETE_ROLE_REQUEST = 'role';
export const EDIT_ROLE_REQUEST = 'role';
export const DETAIL_ROLE_REQUEST = 'role';
export const GET_LIST_ALL_ROLES_EXAMPLE = 'allroles';
//competition
export const LIST_COMPETITION = 'competitions';
export const ADD_COMPETITION = 'competition';
export const MANAGE_COMPETITION = 'competition';
export const DELETE_COMPETITION = 'competition';
export const GET_COMPETITION_REQUEST = 'competition';
export const UPDATE_COMPETITION = 'competition';
export const GET_CANDIDATES = 'getcandidates';
export const GET_STATUS_COMPETITION = 'competitionstatus';
//belt
export const GET_ALL_BELT = 'allbelts';
export const GET_ALL_BELT_COURSE = 'allbelts';

//branch
export const GET_BRANCH_REQUEST = 'branch';
export const GET_ALL_BRANCH = 'branchs';
export const GET_CLUB_IN_BRANCH = 'allclubs';

//dashboards
export const GET_DASHBOARD_INFO = 'dashboard';
export const GET_INFO_UNPUBLISH = 'unpublishs';
export const GET_CHART_DASHBOARD = 'chart';
//event
export const GET_LIST_EVENT = 'events';
export const ADD_EVENT = 'event';
export const UPDATE_EVENT = 'event';
export const GET_EVENT = 'event';
export const DELETE_EVENT = 'event';
export const EVENT_MEMBER = 'eventmembers';

//activites
export const GET_LIST_ACTIVE = 'activities';
export const DELETE_COURSE_DASHBOARD = 'course';

//donate
export const GET_LIST_EVENT_DONATE = 'eventsbytype/3';
export const GET_LIST_EVENT_DONATE_DETAIL = 'livedonates';
export const GET_LIST_DONATE = 'donates';
export const DELETE_DONATE = 'donate';
export const NEW_DONATE_REQUEST = 'donate';
export const GET_DONATE_DETAIL = 'donate';
export const UPDATE_DONATE_REQUEST = 'donate';
export const ACCEPT_DONATE = 'accept';

//course
export const GET_LIST_COURSE = 'courses';
export const NEW_COURSE_REQUEST = 'course';
export const DELETE_COURSE = 'course';
export const GET_COURSE_DETAIL = 'course';
export const UPDATE_COURSE = 'course';

//certificates
export const GET_LIST_CERTIFICATE = 'certificates';
export const NEW_CERTIFICATE_REQUEST = 'certificate';
export const DELETE_CERTIFICATE_REQUEST = 'certificate';
export const DETAIL_CERTIFICATE_REQUEST = 'certificate';
export const UPDATE_CERTIFICATE_REQUEST = 'certificate';

//history club
export const GET_LIST_HISTORY_CLUB = 'userclubhistories';
export const NEW_HISTORY_CLUB = 'userclubhistory';
export const EDIT_HISTORY_CLUB = 'userclubhistory';
export const DETAIL_HISTORY_CLUB = 'userclubhistory';
export const DELETE_HISTORY_CLUB = 'userclubhistory';

// forget password
export const FORGET_PASSWORD = 'user/forgot-pass';

//ads

export const GET_LIST_ADS = 'advertisements';
export const NEW_LIST_ADS = 'advertisement';
export const GET_DETAIL_ADS = 'advertisement';
export const UPDATE_ADS = 'advertisement';
export const DELETE_ADS = 'advertisement';

////certificates
export const GET_LIST_HONORS = 'gethonors';
export const NEW_HONORS_REQUEST = 'honor';
export const DELETE_HONORS_REQUEST = 'honor';
export const DETAIL_HONORS_REQUEST = 'honor';
export const UPDATE_HONORS_REQUEST = 'honor';

//form

export const GET_LIST_FORM = 'forms';
export const NEW_LIST_FORM = 'form';
export const GET_DETAIL_FORM = 'form';
export const UPDATE_FORM = 'form';
export const DELETE_FORM = 'form';

//postweb
export const GET_LIST_MENU = 'menus';
export const GET_DETAIL_MENU = 'menu';
export const GET_LIST_POSTWEB = 'postwebs';
export const NEW_LIST_POSTWEB = 'postweb';
export const GET_DETAIL_POSTWEB = 'postweb';
export const UPDATE_POSTWEB = 'postweb';
export const DELETE_POSTWEB = 'postweb';

//banner

export const GET_LIST_BANNER = 'banners';
export const NEW_LIST_BANNER = 'banner';
export const GET_DETAIL_BANNER = 'banner';
export const UPDATE_BANNER = 'banner';
export const DELETE_BANNER = 'banner';

// group branch
export const GET_GROUP_BRANCH_REQUEST = 'areas';
export const GET_DETAIL_BRANCH_REQUEST = 'area';

//contact
export const GET_LIST_CONTACT = 'contactinfos';
export const GET_LIST_CONTACT1 = 'contactinfo';

//dashboard

export const GET_ALL_STATISTIC = 'new/statistic';
export const GET_ALL_CHART = 'new/chart';
export const GET_ACTIVATE = 'new/activate';
export const GET_STUDENT = 'users';

export const GET_BRANCH_BY_ID = 'allbranchs';
export const GET_ALL_PIE_CHART = 'new/martialarts';
export const GET_PIE_CHART_AGE = 'new/martialage';
