import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  GET_ALL_STATISTIC,
  GET_ALL_CHART,
  GET_BRANCH_BY_ID,
  GET_GROUP_BRANCH_REQUEST,
  GET_LIST_BRANCH,
  GET_ALL_PIE_CHART,
  GET_ACTIVATE,
  GET_STUDENT,
  GET_LIST_CLUB,
  GET_PIE_CHART_AGE,
  GET_CLUB_BRANCH
} from '../../constants/config';
import {
  getDataByID,
  getTakenData,
  postDataWithParams
} from '../../services/base_services';

import { Type } from '../../constants/dashboard';
import { showMessage } from '../../helpers/table';

import iconStudent from '../../assets/images/icon/icon-student.svg';
import iconPostweb from '../../assets/images/icon/icon-postweb.svg';
import iconActivity from '../../assets/images/icon/icon-activiti.svg';

import Select from '../../component/common/Select';
import Statistic from './Statistic';
import LineChartCommon from './LineChart';
import PieChartCommon from './PieChart';
import ActivateTable from '../Dashboard/ActivateTable';
import StudentTable from './StudentTable';

import '../Dashboard/Dashboard.scss';

const Dashboard = (props) => {
  const { t } = useTranslation();
  const { user } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [statistic, setStatistic] = useState([]);
  const [lineChart, setLineChart] = useState([]);
  const [pieChart, setPieChart] = useState([]);
  const [pieChartAge, setPieChartAge] = useState([]);
  const [activate, setActivate] = useState([]);
  const [student, setStudent] = useState([]);
  const [dataArea, setDataArea] = useState(null);
  const [dataBranch, setDataBranch] = useState([
    {
      id: '',
      name: t('dashboard.branch')
    }
  ]);
  const [dataClub, setDataClub] = useState([
    {
      id: '',
      name: t('dashboard.club')
    }
  ]);
  const [dataFilter, setDataFilter] = useState({
    type: Type.ALL,
    area: '',
    branch: '',
    club: ''
  });
  const [filterAge, setFilterAge] = useState({
    area_id: '',
    branch_id: '',
    club_id: ''
  });

  const fetchData = useCallback(async () => {
    if (user?.position_id == 1) {
      try {
        setIsLoading(true);
        const results = await Promise.allSettled([
          getTakenData(GET_GROUP_BRANCH_REQUEST),
          getTakenData(GET_LIST_BRANCH)
        ]);

        const areaData =
          results[0].status === 'fulfilled' ? results[0].value?.data : [];
        const branchData =
          results[1].status === 'fulfilled' ? results[1].value?.data : [];
        setDataArea(areaData);
        branchData.length == 1
          ? setDataBranch([...branchData])
          : setDataBranch([
              {
                id: '',
                name: t('dashboard.club')
              },
              ...branchData
            ]);
      } catch (error) {
        console.error(t('dashboard.error'), error);
      } finally {
        setIsLoading(false);
      }
    } else {
      getTakenData(GET_LIST_BRANCH)
        .then((res) => {
          setDataBranch(res?.data);
          return res?.data?.[0]?.id;
        })
        .then((id) => {
          getDataByID(GET_CLUB_BRANCH, id)
            .then((res) => {
              if (user?.position_id == 2) {
                setDataClub(
                  [
                    {
                      id: '',
                      name: t('dashboard.club')
                    },
                    ...res?.data
                  ].sort((a, b) => a?.id - b?.id)
                );
              } else {
                setDataClub([
                  res?.data?.find((data) => data?.id === user?.club_id)
                ]);
              }
            })
            .catch(
              (error) => showMessage(`${t('dashboard.error')} ${error}`),
              false
            );
        });
    }
  }, []);

  const getBranchByArea = (id) => {
    getDataByID(GET_BRANCH_BY_ID, id)
      .then((res) => {
        setDataBranch(
          [
            {
              id: '',
              name: t('dashboard.branch')
            },
            ...res?.data
          ].sort((a, b) => a.id - b.id)
        );
      })
      .catch((error) => showMessage(`${t('dashboard.error')} ${error}`), false);
  };

  const getClubByBranch = (param) => {
    postDataWithParams(GET_LIST_CLUB, param)
      .then((res) => {
        setDataClub([
          {
            id: '',
            name: t('dashboard.club')
          },
          ...res?.data?.data
        ]);
      })
      .catch((error) => showMessage(`${t('dashboard.error')} ${error}`), false);
  };

  const handleChangeArea = (e) => {
    const area = e.target.value;
    setDataFilter({ ...dataFilter, area: area, branch: 0, club: '' });
    setFilterAge({ ...filterAge, area_id: area, branch_id: '', club_id: '' });
    setDataClub([
      {
        id: '',
        name: t('dashboard.club')
      }
    ]);
    getBranchByArea(area);
  };

  const handleChangeBranch = (e) => {
    const branch = e.target.value;
    setDataFilter({ ...dataFilter, branch: branch, club: '' });
    setFilterAge({ ...filterAge, branch_id: branch, club_id: '' });
    if (branch) {
      getClubByBranch({ ...dataFilter, branch: branch, club: '' });
    } else {
      setDataClub([
        {
          id: '',
          name: t('dashboard.club')
        }
      ]);
    }
  };

  const handleChangeClub = (e) => {
    const club = e.target.value;
    setDataFilter({ ...dataFilter, club: club });
    setFilterAge({ ...filterAge, club_id: club });
  };

  const handleChangeTime = (e) => {
    setDataFilter({ ...dataFilter, type: e.target.value });
  };

  const getStatistic = () => {
    postDataWithParams(GET_ALL_STATISTIC, dataFilter)
      .then((res) => {
        setStatistic(res?.data?.data);
      })
      .catch((error) => showMessage(`${t('dashboard.error')} ${error}`), false);
  };

  const getLineChartData = () => {
    postDataWithParams(GET_ALL_CHART, dataFilter)
      .then((res) => {
        setLineChart(res?.data?.data);
      })
      .catch((error) => showMessage(`${t('dashboard.error')} ${error}`), false);
  };

  const getPieChartData = () => {
    postDataWithParams(GET_ALL_PIE_CHART, dataFilter)
      .then((res) => {
        setPieChart(res?.data?.data);
      })
      .catch((error) => showMessage(`${t('dashboard.error')} ${error}`), false);
  };

  const getPieChartDataAge = () => {
    postDataWithParams(GET_PIE_CHART_AGE, filterAge)
      .then((res) => {
        setPieChartAge(res?.data?.data);
      })
      .catch((error) => showMessage(`${t('dashboard.error')} ${error}`), false);
  };

  const getActivatetData = () => {
    postDataWithParams(GET_ACTIVATE, {
      page: 1,
      per_page: 10
    })
      .then((res) => {
        setActivate(res?.data?.activate);
      })
      .catch((error) => showMessage(`${t('dashboard.error')} ${error}`), false);
  };

  const getStudentData = () => {
    postDataWithParams(GET_STUDENT, {
      page: 1,
      per_page: 10
    })
      .then((res) => {
        setStudent(res?.data?.data);
      })
      .catch((error) => showMessage(`${t('dashboard.error')} ${error}`), false);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    getStatistic();
    getLineChartData();
    getPieChartData();
    getActivatetData();
    getStudentData();
    getPieChartDataAge();
  }, [dataFilter]);

  return isLoading ? (
    ''
  ) : (
    <>
      <div className="body-right-bottom">
        <div className="container-fluid ">
          <div className="row top-table">
            <div className="col-md-12 top-table-title">
              <p>{t('dashboard.information')}</p>
            </div>
            <div className="col-md-1 top-table-border "></div>
          </div>

          <div className="dashboard">
            <div className="col-md-12 dashboard-select">
              <div className="row ">
                <div className="col-md-3">
                  {user?.position_id == 1 && (
                    <Select
                      className="form-control-select input-form col-md-3"
                      value={dataFilter?.area}
                      name="name"
                      key_value="id"
                      key_label="name"
                      data={dataArea}
                      errors={[]}
                      include_blank={t('dashboard.area')}
                      onChange={(e) => handleChangeArea(e)}
                    />
                  )}
                </div>
                <div className="col-md-3">
                  {user?.position_id < 2 && (
                    <Select
                      className="form-control-select input-form col-md-3"
                      name="name"
                      key_value="id"
                      key_label="name"
                      data={dataBranch}
                      errors={[]}
                      value={dataFilter?.branch}
                      onChange={(e) => {
                        handleChangeBranch(e);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-3">
                  {user?.position_id < 3 && (
                    <Select
                      className="form-control-select input-form  col-md-3"
                      name="name"
                      key_value="id"
                      key_label="name"
                      data={dataClub}
                      errors={[]}
                      value={dataFilter?.club}
                      onChange={(e) => {
                        handleChangeClub(e);
                      }}
                    />
                  )}
                </div>
                <div className="col-md-3">
                  <Select
                    className="form-control-select input-form  col-md-3"
                    name="name"
                    value={dataFilter.type}
                    key_value="value"
                    key_label="type"
                    data={[
                      { type: 'Tất cả thời gian', value: 'ALL' },
                      { type: 'Tuần này', value: 'WEEK' },
                      { type: 'Tháng này', value: 'MONTH' },
                      { type: 'Năm nay', value: 'YEAR' }
                    ]}
                    errors={[]}
                    onChange={(e) => {
                      handleChangeTime(e);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12 dashboard-statistic">
              <div className="row ">
                <div className="col-md-4">
                  <Statistic
                    title={t('dashboard.student')}
                    valueCurrent={statistic?.countUser}
                    valuePre={statistic?.countUserPre}
                    image={iconStudent}
                  />
                </div>
                <div className="col-md-4 ">
                  <Statistic
                    title={t('dashboard.posts')}
                    valueCurrent={statistic?.countInformation}
                    valuePre={statistic?.countInformationPre}
                    image={iconPostweb}
                  />
                </div>
                <div className="col-md-4 ">
                  <Statistic
                    title={t('dashboard.activity')}
                    valueCurrent={statistic?.countActivate}
                    valuePre={statistic?.countActivatePre}
                    image={iconActivity}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12 dashboard-char">
              <div className="row">
                <div className="col-md-8 dashboard-char-line">
                  <LineChartCommon
                    // options={options}
                    dataLineChart={lineChart}
                  />
                </div>
                <div className="col-md-4 dashboard-char-pie">
                  <PieChartCommon
                    dataPieChart={pieChart}
                    dataPieChartAge={pieChartAge}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12 dashboard-table">
              <div className="row ">
                <div className="col-md-8 dashboard-table-activate">
                  <ActivateTable dataActivate={activate} />
                </div>
                <div className="col-md-4 ">
                  <StudentTable dataStudent={student} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user
  };
};

export default connect(mapStateToProps, null)(withRouter(Dashboard));
