export const bannerTypeEnum = [
  {
    label: 'Chọn banner',
    key: ''
  },
  {
    label: 'Trang chủ',
    key: 'HOME_PAGE'
  },
  {
    label: 'Lịch sử',
    key: 'HISTORY'
  },
  {
    label: 'Phân đường',
    key: 'BRANCH'
  },
  {
    label: 'Kỹ thuật',
    key: 'TECHNIQUE'
  },
  {
    label: 'Hoạt động',
    key: 'OPERATION'
  },
  {
    label: 'Thư viện',
    key: 'LIBRARY'
  },
  {
    label: 'Thông tin',
    key: 'INFORMATION'
  },
  {
    label: 'Liên hệ',
    key: 'CONTACT'
  },
  {
    label: 'Tìm kiếm',
    key: 'SEARCH'
  }
];
