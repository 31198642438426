import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import InputSearch from '../../component/common/InputSearch';
import NewButton from '../../component/common/NewButton';
import Paginate from '../../component/Paginate/index';
import Table from '../../component/common/TableStudent';
import Select from '../../component/common/Select';
import Datepicker from '../../component/common/Datepicker';

import { ADMIN_ROUTES } from '../../constants/router';
import { PER_PAGE } from '../../constants/variable';
import { confirmDelete, showMessage, confirmDoc } from '../../helpers/table';
import { destructServerErrors } from '../../helpers/error';
import { formatDate } from '../../helpers/form';
import {
  LIST_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_REQUEST,
  POST_DOCUMENT_REQUEST,
  REJECT_DOCUMENT_REQUEST,
  GET_LIST_INFO
} from '../../constants/config';
import {
  postDataWithParams,
  deleteById,
  putDataUrl,
  getTakenData
} from '../../services/base_services';

import editIcon from '../../assets/images/icon/edit.svg';
import deleteIcon from '../../assets/images/images/delete.png';
import detail from '../../assets/images/images/detail.svg';
import accpect from '../../assets/images/accept.svg';

const TableDocument = ({ user }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [perPage] = useState(PER_PAGE);
  const [paginate, setPaginate] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [infoCatalog, setInfoCatalog] = useState([]);
  const [filter, setFilter] = useState({
    page: 1,
    per_page: PER_PAGE,
    keyword: '',
    info_catalog_id: '',
    post_date_from: '',
    post_date_to: ''
  });
  let timer = null;

  const tableHeaders = [
    {
      label: t('document.stt'),
      index: 'stt',
      option: {
        className: 'text-center position-relative'
      },
      callback: null
    },
    {
      label: t('document.title'),
      index: null,
      option: {
        className: 'width-300'
      },
      callback: (data) => (
        <div>
          <span>{data && data.title}</span>
        </div>
      )
    },
    {
      label: t('document.postDate'),
      index: null,
      option: {
        className: 'text-center width-200'
      },
      callback: (data) => (
        <div>
          <span>{data && data.updated_at && formatDate(data.updated_at)}</span>
        </div>
      )
    },
    {
      label: t('document.author'),
      index: null,
      option: {
        className: 'text-center width-200'
      },
      callback: (data) => (
        <div>
          <span>{data && data.user && data.user.name}</span>
        </div>
      )
    },
    {
      label: t('document.articleType'),
      index: null,
      option: {
        className: 'text-center width-200'
      },
      callback: (data) => (
        <div>
          <span>{data && data.infoCatalog && data.infoCatalog.name}</span>
        </div>
      )
    },
    {
      label: t('document.status'),
      index: null,
      option: {
        className: 'text-center width-200'
      },
      callback: (data) => {
        switch (data && data.status) {
          case 0:
            return (
              <div>
                <span>{t('document.saveDraft')}</span>
              </div>
            );
          case 1:
            return (
              <div>
                <span>{t('document.pending')}</span>
              </div>
            );
          case 2:
            return (
              <div>
                <span>{t('document.posted')}</span>
              </div>
            );
          default:
            return null;
        }
      }
    },
    {
      label: t('document.action'),
      index: null,
      option: {
        className: 'text-center fixed-collumn'
      },
      callback: (data) => {
        if (data.id) {
          return (
            <div className="btn-group">
              {(data && data.status === 2) ||
              data.status === 0 ||
              (user && user.position_id === 1 && !user.admin_flg) ||
              (user && user.position_id === 2) ||
              (user && user.position_id === 3) ? (
                ''
              ) : (
                <span
                  data-tip="document.approval"
                  className="link-action ml-2 mr-2"
                >
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => postDocument(data.id)}
                    onKeyPress={() => {}}
                  >
                    <img
                      alt="edit"
                      src={accpect}
                      className="btn-icon"
                      style={{ maxWidth: '19px' }}
                    />
                  </div>
                </span>
              )}
              <span
                data-tip={t('document.details')}
                className="link-action ml-2 mr-2"
              >
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => onDetailStudent(data.id)}
                  onKeyPress={() => {}}
                >
                  <img alt="detail" src={detail} className="btn-icon" />
                </div>
              </span>
              {(user && user.admin_flg) || (user && user.id === data.author) ? (
                <span
                  data-tip={t('document.edit')}
                  className="link-action ml-2 mr-2"
                >
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => onEditStudent(data.id)}
                    onKeyPress={() => {}}
                  >
                    <img alt="edit" src={editIcon} className="btn-icon" />
                  </div>
                </span>
              ) : null}
              {(user && user.admin_flg) || (user && user.id === data.author) ? (
                <span
                  data-tip={t('document.delete')}
                  className="link-action ml-2"
                >
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => onDeleteStudent(data.id)}
                    onKeyPress={() => {}}
                  >
                    <img alt="delete" src={deleteIcon} className="btn-icon" />
                  </div>
                </span>
              ) : null}
              <ReactTooltip
                className="tooltip-button"
                place="bottom"
                effect="float"
              />
            </div>
          );
        }
        return null;
      }
    }
  ];

  useEffect(() => {
    getListDocument();
  }, [filter]);

  useEffect(() => {
    getTakenData(GET_LIST_INFO).then((res) => {
      setInfoCatalog([
        { id: '', name: t('document.articleType') },
        ...res?.data
      ]);
    });
  }, []);

  const onEditStudent = (id) => {
    history.push(`${ADMIN_ROUTES.ACTIVE_DOCUMENTS}/edit/${id}`);
  };

  const onDetailStudent = (id) => {
    history.push(`${ADMIN_ROUTES.ACTIVE_DOCUMENTS}/detail/${id}`);
  };

  const onDeleteStudent = async (id) => {
    try {
      const res = await confirmDelete();
      if (res.value) {
        await removeStudentRequest(id);
      }
    } catch (error) {
      // Handle error appropriately
    }
  };

  const removeStudentRequest = async (id) => {
    try {
      const res = await deleteById(DELETE_DOCUMENT_REQUEST, id);
      showMessage(res.data.message, true);
      getListDocument();
    } catch (error) {
      showMessage(t('document.error'), false);
    }
  };

  const postDocument = async (id, dan) => {
    try {
      const res = await confirmDoc(dan);
      if (res.isConfirmed) {
        const val = document.getElementById('textarea-sw').value;
        await postRejectDoc(id, val);
      }
      if (res.isDenied) {
        await postDocumentRequest(id);
      }
    } catch (error) {
      // Handle error appropriately
    }
  };

  const postDocumentRequest = async (id) => {
    try {
      const res = await putDataUrl(POST_DOCUMENT_REQUEST, { id });
      showMessage(res.data.message, true);
      getListDocument();
    } catch (error) {
      showMessage(t('document.error'), false);
    }
  };

  const postRejectDoc = async (id, text) => {
    try {
      const res = await putDataUrl(REJECT_DOCUMENT_REQUEST, {
        id,
        content: text
      });
      showMessage(res.data.message, true);
      getListDocument();
    } catch (err) {
      const errs = destructServerErrors(err);
      postDocument(id, errs.content);
    }
  };

  const getListDocument = async () => {
    try {
      const res = await postDataWithParams(LIST_DOCUMENT_REQUEST, filter);
      setTableData(res.data.data);
      setPaginate({
        ...res.data.paginate,
        perPage
      });
      setIsLoading(false);
    } catch (error) {
      showMessage(`${t('document.error')} ${error}`, false);
    }
  };

  const onChangeSearch = (e) => {
    setFilter({ ...filter, keyword: e });
  };

  const onSubmitSearch = useCallback((e) => {
    e.preventDefault();
    clearTimeout(timer);
    const value = e.target.value || '';
    setKeyword(value);
    timer = setTimeout(() => {
      setPage(1);
    }, 500);
  }, []);

  const changeInfoCatalog = (e) => {
    setFilter({ ...filter, info_catalog_id: e });
  };

  const handleChangeDateFrom = (date) => {
    if (date && date > new Date()) {
      showMessage(t('document.errorDate'));
      return;
    }
    setFilter({
      ...filter,
      post_date_from: formatDate(date, 'yyyy-mm-dd')
    });
  };

  const handleChangeDateTo = (date) => {
    setFilter({ ...filter, post_date_to: formatDate(date, 'yyyy-mm-dd') });
  };

  const changePage = (pageNumber) => {
    if (pageNumber !== page) {
      setFilter({ ...filter, page: pageNumber });
    }
  };

  return (
    <>
      <div className="body-right-bottom course-wrap">
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col-md-12 top-table-title">
              <p>{t('document.header')}</p>
            </div>
            <div className="col-md-1 top-table-border "></div>
            <div className="col-md-12 top-table-text">
              {t('document.headerForm')}
            </div>
          </div>

          <div className="row header-wrap">
            <div className="col-sm-12 col-md-3 col-lg-3 ">
              <InputSearch onChange={(e) => onChangeSearch(e.target.value)} />
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3">
              <Select
                className="form-control-select input-form col-md-3"
                name="info_catalog_id"
                key_value="id"
                key_label="name"
                data={infoCatalog}
                errors={[]}
                value={filter.info_catalog_id}
                onChange={(e) => changeInfoCatalog(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3">
              <Datepicker
                name="post_date_from"
                value={filter.post_date_from}
                onChange={handleChangeDateFrom}
                errors={[]}
                clearIcon={false}
                className="form-control input-form"
                format="dd-MM-yyyy"
              />
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3">
              <Datepicker
                name="post_date_to"
                value={filter.post_date_to}
                onChange={handleChangeDateTo}
                errors={[]}
                clearIcon={false}
                className="form-control input-form"
                format="dd-MM-yyyy"
              />
            </div>
          </div>
          <div className="row header-wrap">
            <div></div>
            <div className="col-sm-12 col-md-3 col-lg-3  content__flex-end">
              <Link to="/active/documents/new">
                <NewButton />
              </Link>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Table
                fixed
                tableHeaders={tableHeaders}
                tableData={tableData}
                tablePaginate={paginate}
                isLoading={isLoading}
              />
            </div>
            <div className="table-pagenatie ml-auto">
              <div className="paginate-wrapper">
                <Paginate paginate={paginate} changePage={changePage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
TableDocument.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user
  };
};

export default connect(mapStateToProps)(TableDocument);
